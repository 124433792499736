import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { permissionTags } from "../../../configs/permissionTags";
import { table as Table } from "../../ui/table/Table";
import * as apiUtils from "../../../common/utils/apiGateway";
import { canAllow } from "../../../common/utils/utils";
import * as actions from "./action";
import { connect } from "react-redux";
import "./feStatus.scss";
import onlineMarker from "../../../assets/img/onlineMarker_feStatus.svg";
import moment from "moment-timezone";

const FEStatusDashboard = (props) => {
  const { Option } = Select;

  const [feStatusUpdatePermission] = useState({
    read: canAllow(permissionTags.hyperlocal.fe_status.read),
    create: canAllow(permissionTags.hyperlocal.fe_status.create),
    update: canAllow(permissionTags.hyperlocal.fe_status.update),
  });

  const [disableReasons, setDisableReasons] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [enableSelectedReason, setEnableSelectedReason] = useState(undefined);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [rowId, setRowId] = useState("");
  const [isDeliveryHistory, setIsDeliveryHistory] = useState(false);
  const [deliveryHistory, setDeliveryHistory] = useState([]);
  const [isSearchNotFound, setIsSearchNotFound] = useState(false);
  const [finalCount, setFinalCount] = useState({});
  const [isOnlineButtonActive, setIsOnlineButtonActive] = useState(false);
  const [isOfflineButtonActive, setIsOfflineButtonActive] = useState(false);
  const [isStaleLocationActive, setIsStaleLocationActive] = useState(false);
  const [isNotStaleLocationActive, setIsNotStaleLocationActive] = useState(
    false
  );
  const [isEligibleButtonActive, setIsEligibleButtonActive] = useState(false);
  const [isInEligibleButtonActive, setIsInEligibleButtonActive] = useState(
    false
  );
  const [isTotalStaffButtonActive, setIsTotalStaffButtonActive] = useState(
    true
  );

  const [rowData, setRowData] = useState([]);
  const [searchedUser, setSearchedUser] = useState(undefined);
  const [userData, setUserData] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isGovernanceV2Enabled, setIsGovernanceV2Enabled] = useState(false);

  const roleNameLocalStorage = localStorage.getItem("roleName");
  let userRole = JSON.parse(roleNameLocalStorage.toUpperCase());

  const EnableReasons = [
    { id: 1, name: "Incorrectly Marked Eligible" },
    { id: 2, name: "Manually Verified" },
    { id: 3, name: "Do not see a system requirement to add reasons" },
  ];

   const governance_enabled_cities = [291]; //governance v2 enabled city list : stage credentials
  //const governance_enabled_cities = [388, 257 , 454]; //prod credentials,  trivandrum north , Warangal
  // const governance_enabled_cities = [1, 388, 257]; //pre-prod credentials,  Blore, trivandrum north, Warangal

  let locale = {
    emptyText: (
      <p
        style={{
          fontSize: "16px",
          color: "red",
          fontWeight: "500",
          height: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        "Governance V2 is not enabled in the selected city"
      </p>
    ),
  };

  let isComponentLoaded = false;

  useEffect(() => {
    props.resetStaffData();
    if (
      !isComponentLoaded ) {
      if (props.locationIds.length > 0 && props.cityId) {
        setIsGovernanceV2Enabled(true);
        let payload = {
          isStaleLocation: null,
          hyperlocalAvailability: [],
          hyperlocalEligibility: [],
          locationIds: props.locationIds,
          userName: null,
          contactNumber: null,
        };
        props.fetchStaffData(payload);
        isComponentLoaded = true;
      }
    } else {
      isComponentLoaded = false;
    }
  }, []);

  useEffect(() => {
    if (isGovernanceV2Enabled) {
      if (props.staffUsers !== undefined) {
        setUserData(props.staffUsers);
        getEligibleUsersCount();
      } else {
        console.log("not found");
      }
    }
  }, [props.staffUsers]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (data, row) => (
        <Row>
          <span style={{ fontSize: "16px", fontWeight: "700" }}>
            {row.name}
          </span>{" "}
          <br />{" "}
          <span
            style={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {row.contactNumber}{" "}
          </span>
          <span
            style={{
              color: "#35a839",
              fontSize: "14px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            {row.hyperlocalAvailability ? (
              <>
                <img src={onlineMarker} alt="online green dot" /> Online
              </>
            ) : (
              ""
            )}
          </span>
        </Row>
      ),
      width: "12%",
    },
    {
      title: "CURRENT STATUS",
      dataIndex: "hyperlocalEligibility",
      key: "hyperlocalEligibility",
      render: (data, row) => (
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tag
              style={{
                marginTop: 10,
                textAlign: "center",
                width: "90%",
                borderRadius: "8px",
              }}
              color={row.hyperlocalEligibility ? "green" : "red"}
            >
              {row.hyperlocalEligibility ? "ELIGIBLE" : "INELIGIBLE"}
            </Tag>
          </div>
        </Row>
      ),
      width: "7%",
    },
    {
      title: "Status Reason",
      dataIndex: "governanceRoles",
      key: "governanceRoles",
      render: (governanceRoles, row) => {
        return (
          <Row>
            {row.hyperlocalEligibility ? (
              <Button onClick={() => showAlert()} type="primary">
                View Details
              </Button>
            ) : (
              <Button onClick={() => showDeliveryHandler(row)} type="primary">
                View Details
              </Button>
            )}
          </Row>
        );
      },
      width: "7%",
    },
    {
      title: "LOCATION STATUS",
      dataIndex: "isStaleLocation",
      key: "isStaleLocation",
      render: (locationStatus, row) => (
        <Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tag
              style={{
                marginTop: 10,
                textAlign: "center",
                width: "90%",
                borderRadius: "8px",
              }}
              color={row.isStaleLocation ? "red" : "green"}
            >
              {row.isStaleLocation ? "Stale Location" : "All Okay"}
            </Tag>
          </div>
        </Row>
      ),
      width: "6%",
    },
    {
      title: "LAST GOVERNANCE UPDATE TIME",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (data, row) => (
        <div>
          <p style={{ fontSize: "14px", fontWeight: 400 }}>
            {moment(data).fromNow()} <br />
            <span style={{ fontSize: "12px" }}>
              ( {moment(data).format("HH:mm a, DD MMM, YYYY")} )
            </span>
          </p>
        </div>
      ),
      width: "10%",
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: "7%",
      render: (row) => (
        <Row>
          {feStatusUpdatePermission.update && (
            <Col span={8}>
              <Tooltip
                placement="top"
                title={
                  !row.governanceRoles.includes(userRole)
                    ? "You dont have permission to update this"
                    : ""
                }
              >
                <Button
                  type={row.hyperlocalEligibility ? "danger" : "primary"}
                  onClick={
                    row.hyperlocalEligibility
                      ? () => handledEligibilityChange(row.userId)
                      : () => handledInEligibilityChange(row.userId)
                  }
                  className="enable-btn-staffStatus"
                  style={{
                    borderRadius: "8px",
                    height: "48px",
                    width: "120px",
                    fontSize: "14px",
                    overflowWrap: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                  disabled={!row.governanceRoles.includes(userRole)}
                >
                  {row.hyperlocalEligibility
                    ? "Mark InEligible"
                    : "Mark Eligible"}
                </Button>
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  const handleDisableChange = (value) => {
    setSelectedReason(value);
  };

  const handleEnableChange = (value) => {
    setEnableSelectedReason(value);
  };

  const handleModalCancel = () => {
    setIsDisabled(false);
    setIsEnabled(false);
    setIsDeliveryHistory(false);
    setDeliveryHistory([]);
    setIsSearchNotFound(false);
    setIsDisabled(false);
    setIsEnabled(false);
    setSelectedReason("");
    setEnableSelectedReason("");
    setIsShowAlert(false);
  };

  const handledEligibilityChange = (rowId) => {
    apiUtils
      .apiRequest("/hyperlocal/v1/users/getDisableReasons")
      .then((res) => {
        setDisableReasons(res.data.response?.feDisableResponse);
        setIsDisabled(true);
        setRowId(rowId);
      });
  };

  const handledInEligibilityChange = (rowId) => {
    setIsEnabled(true);
    setRowId(rowId);
  };

  const getEligibleUsersCount = () => {
    let countAll = 0;
    let countEligible = 0;
    let countIneligible = 0;
    const data = props.staffUsers;
    data &&
      data.map((x) => {
        if (x.hyperlocalEligibility === true) {
          countEligible++;
          countAll++;
        }
        if (x.hyperlocalEligibility === false) {
          countIneligible++;
          countAll++;
        }
      });
    setFinalCount({
      countAll: countAll,
      countEligible: countEligible,
      countIneligible: countIneligible,
    });
    return finalCount;
  };

  const reload = () => {
    setIsEligibleButtonActive(false);
    setIsInEligibleButtonActive(false);
    setIsOnlineButtonActive(false);
    setIsOfflineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);
    setIsTotalStaffButtonActive(true);
    setIsDisabled(false);
    setIsEnabled(false);
    setSelectedReason("");
    setEnableSelectedReason("");
    let payload = {
      isStaleLocation: null,
      hyperlocalAvailability: [],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null,
      contactNumber: null,
    };
    if (searchedUser !== undefined) {
      if (isNaN(parseInt(searchedUser))) {
        payload = { ...payload, userName: searchedUser };
        props.fetchStaffData(payload);
      } else {
        payload = { ...payload, contactNumber: searchedUser };
        props.fetchStaffData(payload);
      }
    } else {
      props.fetchStaffData(payload);
    }
  };

  const handleSubmit = () => {
    let payload;
    if (selectedReason !== "") {
      const selectedGovernanceReason = disableReasons
        .filter(function(rules) {
          return rules.id === selectedReason;
        })
        .map(function(rules) {
          const selectedgovernance = {
            message: rules.ruleMessage,
            governanceLevel: rules.governanceLevel,
            eventType : rules.eventType
          };
          return selectedgovernance;
        });

      payload = {
        userRequestList: [
          {
            userId: rowId,
            action: "MARK_INELIGIBLE_FOR_HYPERLOCAL",
            reason: selectedGovernanceReason[0].message,
            ruleId: selectedReason,
            governanceLevel: selectedGovernanceReason[0].governanceLevel,
            eventType : selectedGovernanceReason[0].eventType
          },
        ],
      };
      props.setPermissions(payload, reload);
    } else if (enableSelectedReason !== "") {
      payload = {
        userRequestList: [
          {
            userId: rowId,
            action: "MARK_ELIGIBLE_FOR_HYPERLOCAL",
            reason: enableSelectedReason,
            ruleId: null,
            governanceLevel: null,
          },
        ],
      };
      props.setPermissions(payload, reload);
      setEnableSelectedReason(undefined);
    }
  };

  const onSearch = (val) => {
    isComponentLoaded = false;
    let payload = {
      isStaleLocation: null,
      hyperlocalAvailability: [],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null,
      contactNumber: null,
    };
    if (isNaN(parseInt(val))) {
      payload = { ...payload, userName: val };
    } else {
      payload = { ...payload, contactNumber: val };
    }
    props.fetchStaffData(payload);
    setIsEligibleButtonActive(false);
    setIsInEligibleButtonActive(false);
    setIsOnlineButtonActive(false);
    setIsOfflineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);
    setIsTotalStaffButtonActive(false);
  };

  const showDeliveryHandler = (row) => {
    setRowData(row);
    const roles = String(rowData.governanceRoles);

    const payload = {
      userIds: [row.userId],
    };
    apiUtils
      .apiRequest({
        url: "/hyperlocal/v1/users/getUserDisabledEvents",
        method: "POST",
        data: payload,
      })
      .then((res) => {
        setDeliveryHistory(res?.data?.response?.userEventLogList);
        setIsDeliveryHistory(true);
      });
  };

  const showAlert = () => {
    setIsShowAlert(true);
  };

  const EligibilityHandler = () => {
    let payload = {
      isStaleLocation: null, // true,false,null
      hyperlocalAvailability: [], // true,false,null
      hyperlocalEligibility: ["ELIGIBLE"], // true,false,null
      locationIds: props.locationIds,
      userName: null, //optional
      contactNumber: null, //optional
    };

    props.fetchStaffData(payload);
    setIsEligibleButtonActive(true);
    setIsInEligibleButtonActive(false);

    setIsOnlineButtonActive(false);
    setIsOfflineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);
    setIsTotalStaffButtonActive(false);
    setSearchedUser("");
  };

  const IneligibilityHandler = () => {
    let payload = {
      isStaleLocation: null,
      hyperlocalAvailability: [],
      hyperlocalEligibility: ["INELIGIBLE"],
      locationIds: props.locationIds,
      userName: null,
      contactNumber: null,
    };
    props.fetchStaffData(payload);
    setIsInEligibleButtonActive(true);
    setIsEligibleButtonActive(false);

    setIsOnlineButtonActive(false);
    setIsOfflineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);
    setIsTotalStaffButtonActive(false);
    setSearchedUser("");
  };

  const hyperlocalAvailabilityHandler = () => {
    let payload = {
      isStaleLocation: null,
      hyperlocalAvailability: ["ONLINE"],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null, //optional
      contactNumber: null, //optional
    };
    props.fetchStaffData(payload);

    setIsOnlineButtonActive(true);
    setIsOfflineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);

    setIsInEligibleButtonActive(false);
    setIsEligibleButtonActive(false);
    setIsTotalStaffButtonActive(false);
    setSearchedUser("");
  };

  const hyperlocalUnavailabilityHandler = () => {
    let payload = {
      isStaleLocation: null,
      hyperlocalAvailability: ["OFFLINE"],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null, //optional
      contactNumber: null, //optional
    };

    props.fetchStaffData(payload);

    setIsOfflineButtonActive(true);
    setIsOnlineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);

    setIsInEligibleButtonActive(false);
    setIsEligibleButtonActive(false);
    setIsTotalStaffButtonActive(false);
    setSearchedUser("");
  };

  const allHyperlocalAvailabilityHandler = (e) => {
    let payload = {
      isStaleLocation: null,
      hyperlocalAvailability: [],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null, //optional
      contactNumber: null, //optional
    };
    props.fetchStaffData(payload);
    setIsOfflineButtonActive(false);
    setIsOnlineButtonActive(false);
    setIsNotStaleLocationActive(false);
    setIsStaleLocationActive(false);
    setIsInEligibleButtonActive(false);
    setIsEligibleButtonActive(false);
    setIsTotalStaffButtonActive(true);
    setSearchedUser("");

    e.stopPropagation();
  };

  const hyperlocalNotStaleHandler = () => {
    let payload = {
      isStaleLocation: false,
      hyperlocalAvailability: [],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null,
      contactNumber: null,
    };
    props.fetchStaffData(payload);
    setIsNotStaleLocationActive(true);
    setIsStaleLocationActive(false);
    setIsOfflineButtonActive(false);
    setIsOnlineButtonActive(false);

    setIsInEligibleButtonActive(false);
    setIsEligibleButtonActive(false);
    setIsTotalStaffButtonActive(false);
    setSearchedUser("");
  };
  const hyperlocalStalenessHandler = () => {
    let payload = {
      isStaleLocation: true,
      hyperlocalAvailability: [],
      hyperlocalEligibility: [],
      locationIds: props.locationIds,
      userName: null,
      contactNumber: null,
    };
    props.fetchStaffData(payload);
    setIsStaleLocationActive(true);
    setIsNotStaleLocationActive(false);
    setIsOfflineButtonActive(false);
    setIsOnlineButtonActive(false);

    setIsInEligibleButtonActive(false);
    setIsEligibleButtonActive(false);
    setIsTotalStaffButtonActive(false);
    setSearchedUser("");
  };

  return (
    <>
      <Card className="FEStatusDashboard">
        <div className="count-div-staffStatus">
          <Button
            className={
              isTotalStaffButtonActive
                ? "total-count-div-selected"
                : "total-count-div"
            }
            onClick={allHyperlocalAvailabilityHandler}
            disabled={!isGovernanceV2Enabled}
          >
            Total Staffs
            <p className="total-count-value">{finalCount.countAll}</p>
          </Button>
          <Button
            className={
              isEligibleButtonActive
                ? "total-count-div-selected"
                : "total-count-div"
            }
            onClick={EligibilityHandler}
            disabled={!isGovernanceV2Enabled}
          >
            Total Eligible Staff
            <p className="total-count-value">{finalCount.countEligible}</p>
          </Button>
          <Button
            className={
              isInEligibleButtonActive
                ? "total-count-div-selected"
                : "total-count-div"
            }
            onClick={IneligibilityHandler}
            disabled={!isGovernanceV2Enabled}
          >
            Total Ineligible Staff
            <p className="total-count-value">{finalCount.countIneligible}</p>
          </Button>
        </div>
        <div className="lower-div-staffStatus">
          <div className="input-filter-grp-staffStatus">
            <div className="input-group-staffStatus">
              <Input.Search
                className="input-staffStatus input-button-staffStatus"
                placeholder="Search for FE phone no/ name"
                onChange={(e) => setSearchedUser(e.target.value)}
                onSearch={(val) => onSearch(val)}
                enterButton
                size="large"
                allowClear
                disabled={!isGovernanceV2Enabled}
                value={searchedUser}
              />
            </div>
            <div className="filter-outer-div-staffStatus">
              <div className="filter-inner-div-staffStatus">
                <div className="filter-btn-div-staffStatus">
                  <Button
                    className={
                      isOnlineButtonActive
                        ? "filter-btn-staffStatus-selected"
                        : "filter-btn-staffStatus"
                    }
                    onClick={hyperlocalAvailabilityHandler}
                    disabled={!isGovernanceV2Enabled}
                  >
                    Online
                    <p
                      onClick={allHyperlocalAvailabilityHandler}
                      className={
                        isOnlineButtonActive
                          ? "cancelBtn-staffStatus-selected"
                          : "display-none-staffStatus-selected"
                      }
                    >
                      X
                    </p>
                  </Button>
                  <Button
                    className={
                      isOfflineButtonActive
                        ? "filter-btn-staffStatus-selected"
                        : "filter-btn-staffStatus"
                    }
                    onClick={hyperlocalUnavailabilityHandler}
                    disabled={!isGovernanceV2Enabled}
                  >
                    Offline
                    <p
                      onClick={allHyperlocalAvailabilityHandler}
                      className={
                        isOfflineButtonActive
                          ? "cancelBtn-staffStatus-selected"
                          : "display-none-staffStatus-selected"
                      }
                    >
                      X
                    </p>
                  </Button>
                </div>
              </div>
              <div className="filter-inner-div-staffStatus">
                <div className="filter-btn-div-staffStatus">
                  <Button
                    className={
                      isNotStaleLocationActive
                        ? "filter-btn-staffStatus-selected"
                        : "filter-btn-staffStatus"
                    }
                    onClick={hyperlocalNotStaleHandler}
                    disabled={!isGovernanceV2Enabled}
                  >
                    Location Not Stale
                    <p
                      onClick={allHyperlocalAvailabilityHandler}
                      className={
                        isNotStaleLocationActive
                          ? "cancelBtn-staffStatus-selected"
                          : "display-none-staffStatus-selected"
                      }
                    >
                      X
                    </p>
                  </Button>
                  <Button
                    className={
                      isStaleLocationActive
                        ? "filter-btn-staffStatus-selected"
                        : "filter-btn-staffStatus"
                    }
                    onClick={hyperlocalStalenessHandler}
                    disabled={!isGovernanceV2Enabled}
                  >
                    Location Stale
                    <p
                      onClick={allHyperlocalAvailabilityHandler}
                      className={
                        isStaleLocationActive
                          ? "cancelBtn-staffStatus-selected"
                          : "display-none-staffStatus-selected"
                      }
                    >
                      X
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table
              bordered
              locale={
                isGovernanceV2Enabled
                  ? {
                      emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                    }
                  : locale
              }
              // {{
              //   emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              // }}
              columns={columns}
              dataSource={isGovernanceV2Enabled ? props.staffUsers : []}
              rowKey={(record) => record.userId}
              loading={props.fleetStatus?.loading}
              className="table-staffStatus"
              pagination={true}
            />
          </div>

          <div>
            <Modal
              title={"Disable Delivery Agent"}
              className="modal-staffStatus"
              visible={isDisabled}
              width={500}
              onCancel={handleModalCancel}
              footer={[
                <Button
                  key="back"
                  type="default"
                  onClick={handleModalCancel}
                  style={{
                    color: "#3B5A98",
                    background: "#E8E8EE",
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  disabled={selectedReason === ""}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Update Status
                </Button>,
              ]}
            >
              <div className="order-active">
                <Select
                  showSearch
                  allowClear
                  value={selectedReason || undefined}
                  placeholder="Select Reason for disabling"
                  onChange={handleDisableChange}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {disableReasons.length > 0 &&
                    disableReasons.map((reason, index) => (
                      <Option
                        key={"index" - reason.id}
                        value={reason.id}
                        className="option-staffStatus"
                      >
                        {reason.ruleMessage}
                      </Option>
                    ))}
                </Select>
              </div>
            </Modal>
            <Modal
              title={"Enable Delivery Agent"}
              className="modal-staffStatus"
              visible={isEnabled}
              width={500}
              onCancel={handleModalCancel}
              footer={[
                <Button
                  key="back"
                  type="default"
                  onClick={handleModalCancel}
                  style={{
                    color: "#3B5A98",
                    background: "#E8E8EE",
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  disabled={enableSelectedReason === ""}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Update Status
                </Button>,
              ]}
            >
              <div className="order-active">
                <Select
                  showSearch
                  allowClear
                  value={enableSelectedReason || undefined}
                  placeholder="Select Reason for enabling"
                  onChange={handleEnableChange}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {EnableReasons.map((reason) => (
                    <Option
                      key={"index" - reason.id}
                      value={reason.name}
                      className="option-staffStatus"
                    >
                      {reason.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Modal>
          </div>
          <div>
            <Modal
              title={"Delivery History"}
              visible={isDeliveryHistory}
              onCancel={handleModalCancel}
              footer={[
                <Button key="submit" type="primary" onClick={handleModalCancel}>
                  Ok
                </Button>,
              ]}
            >
              <div className="order-active">
                <div
                  className="deliveryHistory-div-staffStatus"
                  style={{ padding: "10px" }}
                >
                  <h3>Governance Status: </h3>
                  {deliveryHistory.map((history) => (
                    <p style={{ padding: "3px 2px 0 5px" }}>
                      {history.eventType}{" "}
                    </p>
                  ))}
                </div>
                <div
                  className="deliveryHistory-div-staffStatus"
                  style={{ padding: "10px" }}
                >
                  <h3>Who Can Mark Eligible: </h3>
                  <p style={{ padding: "3px 0 0 5px" }}>
                    {String(rowData.governanceRoles)}
                  </p>
                </div>
              </div>
            </Modal>
          </div>
          <div>
            <Modal
              visible={isSearchNotFound}
              onCancel={handleModalCancel}
              footer={[
                <Button key="submit" type="primary" onClick={handleModalCancel}>
                  Ok
                </Button>,
              ]}
            >
              <div className="order-active">
                Searched user not found. Please try again.
              </div>
            </Modal>
          </div>
          <div>
            <Modal
              visible={isShowAlert}
              onCancel={handleModalCancel}
              footer={[
                <Button key="submit" type="primary" onClick={handleModalCancel}>
                  Ok
                </Button>,
              ]}
            >
              <div className="order-active">User is eligible</div>
            </Modal>
          </div>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  fleetStatus: state.FleetReducer,
  cityList: state.FleetReducer?.cityList?.citiesDetails,
  cityListById: state.FleetReducer?.locations,
  staffUsers: state.FleetReducer?.staffStatus?.hyperlocalUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getCityList: () => dispatch(actions.getCityList()),
  getCityListById: (cityId) => dispatch(actions.getCityListById(cityId)),
  fetchStaffData: (payload) => dispatch(actions.fetchStaffData(payload)),
  setPermissions: (data, callback) =>
    dispatch(actions.setPermissions(data, callback)),
  resetStaffData: () => dispatch(actions.resetStaffData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FEStatusDashboard);
