/* eslint-disable array-callback-return */
import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Select,
  Tag,
  Table,
  Empty,
  Input,
  Tooltip,
  Icon,
  Button,
  Modal,
  notification,
  Switch,
  Popover,
  Checkbox,
  Transfer,
  Spin,
  Radio,
  Alert,
} from "antd";
import "./fleet.scss";
import * as actions from "./action";
import { connect } from "react-redux";
import * as utils from "../../../common/utils/utils";
import moment from "moment";
import { canAllow } from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import _ from "lodash";
import { Constants } from "../../../common/utils/constants";

const { confirm } = Modal;
const Option = Select.Option;

const ITEM_SELECTION_LIMIT = 50;

const colorSet = {
  AVAILABLE: "green",
  ON_DUTY: "orange",
  OFFLINE: "red",
  NA: "grey",
};

const typeSet = {
  AVAILABLE: "Available",
  ON_DUTY: "On Duty",
  OFFLINE: "Offline",
  NA: "NA",
};

const setinitialFilters = {
  attendanceStatuses: [
    { id: 0, name: "Offline", value: false, storedValue: "OFFLINE" },
    { id: 1, name: "Available", value: false, storedValue: "AVAILABLE" },
    { id: 2, name: "On Duty", value: false, storedValue: "ON_DUTY" },
    { id: 3, name: "NA", value: false, storedValue: "NA" },
  ],
  hyperlocalPermission: [
    { id: 0, name: "Enabled", value: false, storedValue: "ENABLED" },
    { id: 1, name: "Disabled", value: false, storedValue: "DISABLED" },
  ],
  hyperlocalEligibility: [
    { id: 0, name: "Eligible", value: true, storedValue: "ELIGIBLE" },
    { id: 1, name: "In-Eligible", value: false, storedValue: "INELIGIBLE" },
  ],
  hyperlocalAvailability: [
    { id: 0, name: "Online", value: false, storedValue: "ONLINE" },
    { id: 1, name: "Offline", value: false, storedValue: "OFFLINE" },
  ],
};

const setZoneFilter = {
  taggedZone: [{ id: 0, name: "None", status: false }],
};

const setClientFilter = {
  taggedClient: [{ id: 0, code: "None", status: false }],
};

class FleetDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      bulkElInelModal: false,
      bulkElInelModalLoading: false,
      bulkElInelModalSelectedKey: {
        inEligibleFes: [],
        eligibleFes: [],
      },
      bulkZoneTagModal: false,
      bulkZoneTagModalLoading: false,
      bulkZoneTagModalSelectedZone: undefined,
      bulkZoneTagModalSelectedKey: {
        untaggedZoneFes: [],
        taggedZoneFes: [],
      },
      untaggedZoneFes: [],
      taggedZoneFes: [],
      bulkZoneTagsSelectedKeys: [],

      bulkClientTagModal: false,
      bulkClientTagModalLoading: false,
      bulkClientTagModalSelectedClient: undefined,
      bulkClientTagModalSelectedKey: {
        untaggedCustomerFes: [],
        taggedCustomerFes: [],
      },
      untaggedCustomerFes: [],
      taggedCustomerFes: [],
      taggedCustomerFesTargets: [],
      taggedClientSelectedKeys: [],

      visibleUsers: false,
      visibleZones: false,
      visibleClients: false,
      visibleboxZones: false,
      visibleboxClients: false,
      checkallZone: false,
      checkallClient: false,
      initalFilters: setinitialFilters,
      initalZoneFilter: setZoneFilter,
      initalClientFilter: setClientFilter,
      checkallBoxZone: false,
      checkallBoxClient: false,
      searchListApplied: false,
      searchListAppliedClient: false,
      locationIdError: false,
      //   resetFilters : setinitialFilters,
      enabledUserBox: [],
      diabledUserBox: [],
      setApplied: false,
      enabledUsers: [],
      disabledUsers: [],
      setdisabledUsers: [],
      setenabledUsers: [],
      seteligibleZonesKeys: [],
      seteligibleBoxZonesKeys: [],
      seteligibleClientsKeys: [],
      seteligibleBoxClientsKeys: [],
      customerTagged: false,
      selectedCountZone: 0,
      selectedCountClient: 0,
      selectedBoxCountZone: 0,
      selectedBoxCountClient: 0,
      zeroValue: false,
      zeroValueClient: false,
      locationIds: [],
      copyKeys: [],
      inEligibleFes: [],
      eligibleFes: [],
      eligibleFesTargets: [],
      eligibleFesSelectedKeys: [],
      fleetPermission: {
        read: canAllow(permissionTags.hyperlocal.fleet.read),
        update: canAllow(permissionTags.hyperlocal.fleet.update),
        create: canAllow(permissionTags.hyperlocal.fleet.create),
        delete: canAllow(permissionTags.hyperlocal.fleet.delete),
        bulk_permission: canAllow(
          permissionTags.hyperlocal.fleet.bulk_permission
        ),
        toggle_permission: canAllow(
          permissionTags.hyperlocal.fleet.toggle_permission
        ),
        bulk_user_edit_permission: canAllow(
          permissionTags.hyperlocal.fleet.bulk_user_edit_permission
        ),
        bulk_zone_tagging_permission: canAllow(
          permissionTags.hyperlocal.fleet.bulk_zone_tagging_permission
        ),
        bulk_client_tagging_permission: canAllow(
          permissionTags.hyperlocal.fleet.bulk_client_tagging_permission
        ),
        cod_permission: canAllow(
          permissionTags.hyperlocal.fleet.cod_permission
        ),
        cod_enable_fe_permission: canAllow(
          permissionTags.hyperlocal.fleet.cod_enable_fe_permission
        ),
      },
      codModal: false,
      codModalLoading: false,
      codInEligibleFes: [],
      codEligibleFes: [],
      codEligibleFesTargets: [],
      codEligibleSelectedKeys: [],
      codModalSelectedKey: {
        codInEligibleFes: [],
        codEligibleFes: [],
      },
      isCodListModiified: false,
      showDeliveryHistory: false,
      selectedNumber: undefined,
      isToggleModalVisible: false,
      toggleReasonCategories: [],
      isToggleModalConfirmButton: false,
      toggleReasonError: undefined,
      toggleSelectedReason: undefined,
      toggleIneligibilitySelectedReason: undefined,
      isToggleReasonSubmitted: false,
      isToggleIneligibilityReasonSubmitted: false,
      toggleSelectedFERow: undefined,
      isToggleIneligibleModalVisible: false,
      isToggleCheck: undefined,
      rowId: "",
      isGovernanceV2Enabled: false,
      isCodDataSaving: false,
      isBulkEligibleSaving: false,
      isBulkEligibleModiified: false,
      isBulkZoneTaggedSaving: false,
      isBulkZoneTaggedModiified: false,
      isBulkClientTaggedSaving: false,
      isBulkClientTaggedModiified: false,
      // users :[]
    };
    this.InputSearchRef = React.createRef();
    this.InputSearchdtaRef = React.createRef();
  }

  // governance_enabled_cities = [291];
   governance_enabled_cities = [291]; //governance v2 enabled city list : stage credentials
  //governance_enabled_cities = [388]; //prod credentials,  trivandrum north
  governance_enabled_cities = [1, 388, 257]; //pre-prod credentials,  trivandrum north

  componentDidMount() {
    if (this.props.locationIds && this.props.cityId) {
      this.setState(
        { locationIds: this.props.locationIds, cityId: this.props.cityId },
        () => {
          this.handleOk();
        }
      );
      this.setState({ isGovernanceV2Enabled: true });
      //      if (this.governance_enabled_cities.includes(this.props.cityId)) {
      //        this.setState({ isGovernanceV2Enabled: true });
      //      }
    }
    this.setState((state) => ({
      columns: [
        {
          title: "Name/Number",
          dataIndex: "name",
          key: "name",
          render: (data, row) => (
            <Row>
              {row?.name} <br /> {row?.contactNumber}
            </Row>
          ),
        },
        {
          title: `${
            this.props.customerTagged === true ? "Eligible " : "Tagged "
          } Client`,
          dataIndex: "taggedCustomers",
          key: "client",
          render: (data, row) => (
            <Row>
              {this.props?.customerTagged && (
                <Col span={24}>
                  <Tooltip
                    placement="top"
                    title={row?.taggedCustomers?.join(", ")}
                  >
                    <div className="clientValues">
                      {row?.taggedCustomers?.join(", ")}
                      <br />
                    </div>
                  </Tooltip>
                  <Popover
                    content={this.getWrapData(row?.taggedCustomers)}
                    title="Tagged Client"
                    trigger="click"
                  >
                    {row?.taggedCustomers?.length > 2 && (
                      <Button type="link" className="moreValues">
                        more
                      </Button>
                    )}
                  </Popover>
                </Col>
              )}
              {!this.props?.customerTagged && (
                <Col span={24}>
                  <div className="clientValues">
                    All
                    <br />
                  </div>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Tagged Zone",
          dataIndex: "primaryZoneNames",
          key: "zones",
          render: (data, row) => (
            <>
              <Tooltip
                placement="top"
                title={row?.primaryZoneNames?.join(", ")}
              >
                <div className="primaryValues">
                  {row?.primaryZoneNames?.join(", ")}
                  <br />
                </div>
              </Tooltip>
              <Popover
                content={this.getWrapData(row?.primaryZoneNames)}
                title="Tagged Zone"
                trigger="click"
              >
                {row?.primaryZoneNames?.length > 1 && (
                  <Button type="link" className="moreValues">
                    more
                  </Button>
                )}
              </Popover>
            </>
          ),
        },
        {
          title: "User Status",
          dataIndex: "hyperlocalAvailability",
          key: "hyperlocalAvailability",
          render: (data, row) => (
            <Row>
              <Tag
                color={colorSet[row?.attendanceStatus]}
                key={row?.attendanceStatus}
              >
                {typeSet[row?.attendanceStatus]}
              </Tag>
              <br />
              <Tag
                style={{ marginTop: 10 }}
                color={!row.hyperlocalAvailability ? "#e74c3c" : "#2ecc71"}
              >
                {row.hyperlocalAvailability
                  ? "HyperLocal Online"
                  : "HyperLocal Offline"}
              </Tag>
            </Row>
          ),
        },
        {
          title: "Hyperlocal Pemission Status",
          dataIndex: "hyperlocalPermission",
          key: "hyperlocalPermission",
          render: (data, row) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tag
                style={{ marginTop: 10 }}
                color={!row.hyperlocalPermission ? "#e74c3c" : "#2ecc71"}
              >
                {row.hyperlocalPermission ? "Enabled" : "Disabled"}
              </Tag>
            </div>
          ),
        },

        {
          title: "Last Update",
          dataIndex: "updatedAt",
          key: "updatedAt",
          render: (data, row) => (
            <div>
              {moment(data).format("DD/MM/YYYY HH:mm a")} <br />{" "}
              <small>{moment(data).fromNow()}</small>
            </div>
          ),
        },
        {
          title: "Eligible",
          key: "hyperlocalEligibility",
          dataIndex: "hyperlocalEligibility",
          render: (data, row) => (
            <Row>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Tooltip
                  placement="top"
                  title={
                    !this.state.fleetPermission.toggle_permission
                      ? "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                      : !!row?.isBlacklisted
                      ? "FE is Blacklisted, Manual Intervention is Blocked, Please Contact Support"
                      : this.state.isGovernanceV2Enabled
                      ? "Please use Staff Status page for changing eligibility"
                      : ""
                  }
                >
                  <Switch
                    checked={row?.hyperlocalEligibility ? true : false}
                    onClick={(e, data) => {
                      console.log(row);
                      this.setState({
                        rowId: row.userId,
                      });
                      this.hyperlocalEligibleCheck(e, row.userId);
                    }}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    defaultChecked
                    disabled={
                      row?.isBlacklisted ||
                      !this.state.fleetPermission.toggle_permission ||
                      this.state.isGovernanceV2Enabled
                    }
                    className="switchColor"
                  />
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={
                    this.state.isGovernanceV2Enabled
                      ? "Please use Staff Status page to view delivery history"
                      : "Delivery History"
                  }
                >
                  <Button
                    style={{ background: "none", border: "none" }}
                    disabled={this.state.isGovernanceV2Enabled}
                    onClick={(data) =>
                      this.showDeliveryHandler(row?.contactNumber)
                    }
                  >
                    <Icon type="eye" />
                  </Button>
                </Tooltip>
              </div>
            </Row>
          ),
        },
        {
          title: "Action",
          dataIndex: "",
          key: "actionbox",
          render: (data, row) => (
            <Row gutter={18}>
              {/* {
                          <Col span={12}><Tooltip placement="top" title={'Force Logout'}><Icon style={{ color: 'red' }} type="poweroff" onClick={() => this.showForceLogout(row.id)} /></Tooltip></Col>
                        } */}
              {/* {state.fleetPermission.delete && row?.hyperlocalPermission ? (
                <Col span={8}>
                  <Tooltip placement="top" title={"Remove Permission"}>
                    <Icon
                      type="delete"
                      onClick={() => this.onSetToDisable(row.userId)}
                    />
                  </Tooltip>
                </Col>
              ) : (
                <></>
              )}
              {state.fleetPermission.create && row?.hyperlocalPermission ? (
                <Col span={8}>
                  <Tooltip placement="top" title={"Add Permission"}>
                    <Icon
                      type="plus"
                      onClick={() => this.onSetEnable(row.userId)}
                    />
                  </Tooltip>
                </Col>
              ) : (
                <></>
              )} */}
              {state.fleetPermission.update && (
                <Col span={8}>
                  <Tooltip placement="top" title={"Edit Tagged Zone"}>
                    <div
                      className="svgAdj"
                      onClick={() => this.taggedZone(row.userId)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.61"
                        height="15.333"
                        viewBox="0 0 16.61 15.333"
                      >
                        <g
                          id="Group_60"
                          data-name="Group 60"
                          transform="translate(-1399.6 -327.484)"
                        >
                          <rect
                            id="Rectangle_736"
                            data-name="Rectangle 736"
                            width="14"
                            height="14"
                            rx="2"
                            transform="translate(1400 328.417)"
                            fill="#fff"
                            stroke="#000"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                            strokeDasharray="1 2"
                          />
                          <path
                            id="Path_213"
                            data-name="Path 213"
                            d="M5647.941-2638.7l-3.428,3.577-2.477,2.378-3.245.794.867-3.172,5.928-5.927,1.342,1.339,1.014,1.011"
                            transform="translate(-4232.296 2968.932)"
                            fill="#fff"
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                </Col>
              )}
              {state.fleetPermission.update && this.props.customerTagged && (
                <Col span={8}>
                  <Tooltip placement="top" title={"Edit Tagged Client"}>
                    <Icon
                      type="form"
                      onClick={() => this.taggedClients(row.userId)}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          ),
        },
      ],
    }));
  }

  getWrapData = (data) => {
    return <div className="listData">{data?.join(", ")}</div>;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.zoneData !== this.props.zoneData) {
      this.getZonecount(this.props.zoneData);
    }

    if (prevProps.customerTagged !== this.props.customerTagged) {
      this.setState({ customerTagged: this.props.customerTagged });
    }

    if (prevProps.clientData !== this.props.clientData) {
      this.getClientcount(this.props.clientData);
    }

    if (prevProps.users !== this.props.users) {
      if (this.state.filterValues) {
        this.applySearch(this.state.filterValues, this.props.users);
      } else {
        this.setState({ users: this.props.users });
      }
    }

    if (prevProps.userResponseList !== this.props.userResponseList) {
      this.handleOk();
      this.setfilterDataAction("disable");
      this.setfilterDataAction("enable");
      this.setState({ userResponseList: this.props.userResponseList });
    }
  }

  getEligiblecount = (zoneData, userId) => {
    let count = 0;
    zoneData.map((x) => {
      if (x.isTagged === true) {
        count++;
      }
    });
    let allchecked = this.checkAllStatus2(zoneData);
    this.setState({
      eligibleZones: zoneData,
      eligibleZones2: zoneData,
      checkallZone: allchecked,
      selectedCountZone: count,
      visibleZones: true,
      userId: userId,
    });
  };

  getZonecount = (zoneData) => {
    zoneData.map((x) => {
      x.status = false;
    });
    this.setState({ zoneData: zoneData });
  };

  getClientcount = (clientData) => {
    clientData.map((x) => {
      x.status = false;
    });
    this.setState({ clientData: clientData });
  };

  taggedZone = (userId) => {
    this.props.getEligibleZones(userId).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        notification["success"]({
          message: "SUCCESS",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.getEligiblecount(res?.data?.response?.userZones, userId);
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.setState({
          visibleClients: false,
          userId: userId,
        });
      }
    });
  };

  getEligibleClientcount = (clientsData, userId) => {
    let count = 0;
    clientsData.map((x) => {
      if (x.isTagged === true) {
        count++;
      }
    });
    let allchecked = this.checkAllStatus2(clientsData);
    this.setState({
      eligibleclients: clientsData,
      eligibleclients2: clientsData,
      checkallClient: allchecked,
      selectedCountClient: count,
      visibleClients: true,
      userId: userId,
    });
  };

  taggedClients = (userId) => {
    this.props.getEligibleClients(userId).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        notification["success"]({
          message: "SUCCESS",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.getEligibleClientcount(
          res?.data?.response?.taggedCustomers,
          userId
        );
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.setState({
          visibleClients: false,
          userId: userId,
        });
      }
    });
  };

  onSetEnable = (userId) => {
    let payload = {
      userRequestList: [
        {
          userId: userId,
          action: "ADD_HYPERLOCAL_PERMISSION",
        },
      ],
    };
    this.props.setPermissions(payload);
  };

  onSetToDisable = (userId) => {
    let payload = {
      userRequestList: [
        {
          userId: userId,
          action: "REMOVE_HYPERLOCAL_PERMISSION",
        },
      ],
    };
    confirm({
      centered: true,
      title: "Are you sure?",
      content: "Do you want to delete this!!!",
      onOk: () => {
        this.props.setPermissions(payload);
      },
      onCancel: () => {},
    });
  };

  hyperlocalEligibleCheck = (e, id) => {
    console.log("e from 610", e);
    let payload = {};
    this.setState({
      toggleSelectedFERow: id,
      isToggleCheck: e,
    });
    console.log("e, toggleCheck from 617", e, this.state.isToggleCheck);
    if (e) {
      this.toggleIneligibilityHandler(); //for e = true, open the small popup
      console.log("from if isToggleCheck", e, this.state.isToggleCheck);
      console.log("from if id", id);
    }
    if (e === false) {
      //for e = false, open the big popup
      this.toggleEligibilityHandler();
      console.log("from else id", id);
    }
    if (e == undefined) {
      e = false;
      console.log("Undefined logged");
    }
  };

  showForceLogout = (id) => {
    confirm({
      centered: true,
      title: "Are you sure?",
      content: "Do you want to forcefully logout this user!!!",
      onOk: () => {
        this.props.userForceLogout(id);
      },
      onCancel: () => {},
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    let payload = {};
    let selectedFiltersData = { ...this.state.initalFilters };
    let setZoneFilter = { ...this.state.initalZoneFilter };
    let setClientFilter = { ...this.state.initalClientFilter };
    let keysBox = [];
    let keyBoxClient = [];
    let setApplied = false;
    Object.keys(selectedFiltersData).map((x) => {
      let arrystat = [];
      selectedFiltersData[x].map((y) => {
        if (y.value === true) {
          arrystat.push(y.storedValue);
        }
      });
      payload[x] = arrystat;
    });
    payload.locationIds = this.state.locationIds;
    payload.fetchTaggedZones = true;
    payload.fetchTaggedCustomers = true;
    payload.fetchZones = true;
    payload.fetchCustomers = true;
    if (setClientFilter["taggedClient"]?.length > 0) {
      setClientFilter["taggedClient"].map((x, index) => {
        if (index !== 0) {
          if (x.status) {
            keyBoxClient.push(x.code);
          }
        }
      });
      if (keyBoxClient.length > 0) {
        payload.taggedCustomerCodes = keyBoxClient;
      }
    }
    if (setZoneFilter["taggedZone"]?.length > 0) {
      setZoneFilter["taggedZone"].map((x, index) => {
        if (index !== 0) {
          if (x.status) {
            keysBox.push(x.name);
            setApplied = true;
          }
        } else {
          if (x.status === true) {
            payload.fetchUntaggedUsers = true;
          }
        }
      });
      if (keysBox.length > 0) {
        payload.hyperlocalZones = keysBox;
      }
    }
    this.props.fetchUserData(payload);
    this.setState({
      visible: false,
      initalZoneFilter: setZoneFilter,
      setApplied: setApplied,
      isCodListModiified: false,
      isBulkEligibleModiified: false,
      isBulkZoneTaggedModiified: false,
      isBulkClientTaggedModiified: false,
    });
  };

  resetFilters = (e) => {
    let resetFiltersData = { ...this.state.initalFilters };
    let setZoneFilter = { ...this.state.initalZoneFilter };
    let zoneData = [...this.state.zoneData];
    let id = 0;
    let startArray = [];
    startArray.push({
      id: id++,
      name: "None",
      status: false,
    });
    setZoneFilter = { taggedZone: startArray };
    Object.keys(resetFiltersData).map((x) => {
      resetFiltersData[x].map((y) => {
        if (y.name === "Enabled") {
          y.value = true;
        } else {
          y.value = false;
        }
      });
    });
    zoneData.map((x) => {
      x.status = false;
    });
    this.setState({
      visible: true,
      initalFilters: resetFiltersData,
      initalZoneFilter: setZoneFilter,
      zonedata: zoneData,
      seteligibleBoxZonesKeys: [],
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  setfilterDataAction = (type) => {
    let payload = {};
    if (type === "disable") {
      payload = {
        locationIds: this.state.locationIds,
        hyperlocalPermission: ["DISABLED"],
        includeNonHyperLocalUsers: true,
      };
    } else {
      payload = {
        locationIds: this.state.locationIds,
        hyperlocalPermission: ["ENABLED"],
      };
    }
    this.props.getFilterData(payload).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        if (type === "disable") {
          this.setState({
            disabledUsers: res?.data?.response?.hyperlocalUsers,
            setdisabledUsers: res?.data?.response?.hyperlocalUsers,
            diabledUserBox: [],
            enabledstroke: false,
            disabledstroke: false,
          });
        } else {
          this.setState({
            enabledUsers: res?.data?.response?.hyperlocalUsers,
            setenabledUsers: res?.data?.response?.hyperlocalUsers,
            enabledUserBox: [],
            enabledstroke: false,
            disabledstroke: false,
          });
        }
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
      }
    });
  };

  addRemoveUserData = () => {
    this.setfilterDataAction("disable");
    this.setfilterDataAction("enable");
    this.setState({
      visibleUsers: true,
    });
  };

  usershandleOk = (e) => {
    this.setState({
      visibleUsers: false,
    });
  };

  usershandleCancel = (e) => {
    this.setState({
      visibleUsers: false,
    });
  };

  onLocationChange = (e) => {
    if (e) {
      this.props.getCityListById(e);
      this.setState({ locationIds: [], cityId: e });
    } else {
      this.setState({ locationIds: [] });
    }
  };

  onpartnerChange = (e) => {
    if (e) {
      this.setState({ locationIds: [e] });
    } else {
      this.setState({ locationIds: [] });
    }
  };

  filterValueBox = (data, id) => {
    let setZoneFilter = { ...this.state.initalZoneFilter };
    setZoneFilter[data].map((x) => {
      if (x.id === id) {
        x.status = !x.status;
      }
    });
    if (id === 0) {
      this.setState({
        initalZoneFilter: setZoneFilter,
        zeroValue: !this.state.zeroValue,
      });
    } else {
      this.setState({ initalZoneFilter: setZoneFilter });
    }
  };

  filterValueBoxClient = (data, id) => {
    let setClientFilter = { ...this.state.initalClientFilter };
    setClientFilter[data].map((x) => {
      if (x.id === id) {
        x.status = !x.status;
      }
    });
    if (id === 0) {
      this.setState({
        initalClientFilter: setClientFilter,
        zeroValueClient: !this.state.zeroValueClient,
      });
    } else {
      this.setState({ initalClientFilter: setClientFilter });
    }
  };

  filterValuesChnages = (data, id) => {
    let setinitialFilters = { ...this.state.initalFilters };
    setinitialFilters[data].map((x) => {
      if (x.id === id) {
        x.value = !x.value;
      }
    });
    this.setState({ initalFilters: setinitialFilters });
  };

  userSearch = (e) => {
    let usersData = [...this.props.users];
    let filter = { partialContentSearch: undefined };
    if (e.target.value === "") {
      this.setState({
        users: usersData,
        filterValues: filter,
      });
    }
  };

  applySearch = (filter, usersDataNew) => {
    let usersData = [];
    if (usersDataNew) {
      usersData = usersDataNew;
    } else {
      usersData = [...this.props.users];
    }
    let filterData = [];
    if (filter.partialContentSearch) {
      const searchInput = utils.trimEachWaybill(filter.partialContentSearch);
      usersData.map((x) => {
        searchInput.map((y) => {
          if (
            x.contactNumber.indexOf(y) !== -1 ||
            x.name.toLowerCase().indexOf(y) !== -1
          ) {
            filterData.push(x);
          }
        });
      });
    } else {
      filterData = usersData;
    }
    this.setState({
      users: filterData,
      filterValues: filter,
    });
  };

  selectedUserSearch = (e) => {
    let filterDisabledData = [...this.state?.setdisabledUsers];
    let filterenabledData = [...this.state?.setenabledUsers];

    if (e.target.value === "") {
      this.setState({
        enabledstroke:
          this.state.filterenabledData === this.state.enabledUserBox
            ? true
            : false,
        disabledstroke:
          this.state.filterDisabledData === this.state.diabledUserBox
            ? true
            : false,
        enabledUsers: filterenabledData,
        disabledUsers: filterDisabledData,
      });
    }
  };

  applySearchUsers = (filter) => {
    let selectedUsersFordisabled = [...this.state?.disabledUsers];
    let selectedUsersForenabled = [...this.state?.enabledUsers];

    let filterenabledData = [];
    let filterDisabledData = [];
    if (filter.partialContentSearch && filter.partialContentSearch !== "") {
      const searchInput = utils.trimEachWaybill(filter.partialContentSearch);
      if (selectedUsersForenabled.length > 0) {
        selectedUsersForenabled.map((x) => {
          searchInput.map((y) => {
            if (
              x.contactNumber.trim().indexOf(y.trim()) !== -1 ||
              x.name
                .toLowerCase()
                .trim()
                .indexOf(y.toLowerCase().trim()) !== -1
            ) {
              filterenabledData.push(x);
            }
          });
        });
      }
      if (selectedUsersFordisabled.length > 0) {
        selectedUsersFordisabled.map((x) => {
          searchInput.map((y) => {
            if (
              x.contactNumber.trim().indexOf(y.trim()) !== -1 ||
              x.name
                .toLowerCase()
                .trim()
                .indexOf(y.toLowerCase().trim()) !== -1
            ) {
              filterDisabledData.push(x);
            }
          });
        });
      }
    } else {
      filterDisabledData = [...this.state?.setdisabledUsers];
      filterenabledData = [...this.state?.setenabledUsers];
    }
    this.setState({
      enabledstroke:
        this.state.filterenabledData === this.state.enabledUserBox
          ? true
          : false,
      disabledstroke:
        this.state.filterDisabledData === this.state.diabledUserBox
          ? true
          : false,
      enabledUsers: filterenabledData,
      disabledUsers: filterDisabledData,
    });
  };

  onChekedFordisabled = (item) => {
    let selectedUsersFordisabled = [...this.state.diabledUserBox];
    if (!selectedUsersFordisabled.includes(item)) {
      selectedUsersFordisabled.push(item);
    } else {
      selectedUsersFordisabled.splice(
        selectedUsersFordisabled.indexOf(item),
        1
      );
    }
    this.setState({ diabledUserBox: selectedUsersFordisabled });
  };

  onChekedForenabled = (item) => {
    let selectedUsersForenabled = [...this.state.enabledUserBox];
    if (!selectedUsersForenabled.includes(item)) {
      selectedUsersForenabled.push(item);
    } else {
      selectedUsersForenabled.splice(selectedUsersForenabled.indexOf(item), 1);
    }
    this.setState({ enabledUserBox: selectedUsersForenabled });
  };

  swapLeftUser = () => {
    let selectedUsersForenabled = [...this.state.enabledUserBox];
    if (selectedUsersForenabled.length > 0) {
      let arrayStruct = [];
      selectedUsersForenabled.map((x) => {
        let objectBox = {};
        objectBox.userId = x;
        objectBox.action = "REMOVE_HYPERLOCAL_PERMISSION";
        arrayStruct.push(objectBox);
      });
      let payload = {
        userRequestList: arrayStruct,
      };
      this.props.setPermissions(payload);
    } else {
      return;
    }
  };

  reloadList = () => {
    this.handleOk();
  };

  swapRightUser = () => {
    let selectedUsersFordisabled = [...this.state.diabledUserBox];
    if (selectedUsersFordisabled.length > 0) {
      let arrayStruct = [];
      selectedUsersFordisabled.map((x) => {
        let objectBox = {};
        objectBox.userId = x;
        objectBox.action = "ADD_HYPERLOCAL_PERMISSION";
        arrayStruct.push(objectBox);
      });
      let payload = {
        userRequestList: arrayStruct,
      };
      this.props.setPermissions(payload);
    } else {
      return;
    }
  };

  selectAllEnable = (e) => {
    let selectedUsersForenabled = [...this.state.enabledUsers];
    let formattedData = [];
    if (e.target.checked) {
      selectedUsersForenabled.map((x) => {
        formattedData.push(x.userId);
      });
    } else {
      formattedData = [];
    }
    this.setState({
      enabledUserBox: formattedData,
      enabledstroke: e.target.checked,
    });
  };

  selectAllDisable = (e) => {
    let selectedUsersFordisabled = [...this.state.disabledUsers];
    let formattedData = [];
    if (e.target.checked) {
      selectedUsersFordisabled.map((x) => {
        formattedData.push(x.userId);
      });
    } else {
      formattedData = [];
    }
    this.setState({
      diabledUserBox: formattedData,
      disabledstroke: e.target.checked,
    });
  };

  applySearchzones = () => {
    this.setState({
      eligibleZones: this.state.eligibleZones,
    });
  };

  applySearchClients = () => {
    this.setState({
      eligibleclients: this.state.eligibleclients,
    });
  };

  applySearchzonesBox = () => {
    this.setState({
      zoneData: this.state.zoneData,
    });
  };

  applySearchClientsBox = () => {
    this.setState({
      clientData: this.state.clientData,
    });
  };

  showList2 = (e) => {
    let checkgeoJson = [...this.props.zoneData];
    let filtergeoJson = [];
    if (e.target.value && e.target.value !== "") {
      filtergeoJson = checkgeoJson.filter(
        (x) => x.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    } else {
      filtergeoJson = checkgeoJson;
    }
    let allchecked = this.checkAllStatus(filtergeoJson);
    let getCountOf = this.getCountOf();
    this.setState({
      zoneData: filtergeoJson,
      checkallBoxClient: allchecked,
      selectedBoxCountZone: getCountOf,
      searchListApplied: true,
    });
  };

  showListClient = (e) => {
    let checkgeoJson = [...this.props.clientData];
    let filtergeoJson = [];
    if (e.target.value && e.target.value !== "") {
      filtergeoJson = checkgeoJson.filter(
        (x) => x.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    } else {
      filtergeoJson = checkgeoJson;
    }
    let allchecked = this.checkAllStatus(filtergeoJson);
    let getCountOfClient = this.getCountOfClient();
    this.setState({
      clientData: filtergeoJson,
      checkallBoxClient: allchecked,
      selectedBoxCountClient: getCountOfClient,
      searchListAppliedClient: true,
    });
  };

  showList = (e) => {
    let checkgeoJson = [...this.state.eligibleZones2];
    let filtergeoJson = [];
    if (e.target.value && e.target.value !== "") {
      filtergeoJson = checkgeoJson.filter(
        (x) =>
          x.zoneName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    } else {
      filtergeoJson = checkgeoJson;
    }
    this.setState({
      eligibleZones: filtergeoJson,
    });
  };

  showListClients = (e) => {
    let checkgeoJson = [...this.state.eligibleclients2];
    let filtergeoJson = [];
    if (e.target.value && e.target.value !== "") {
      filtergeoJson = checkgeoJson.filter(
        (x) =>
          x.customerCode.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
          -1
      );
    } else {
      filtergeoJson = checkgeoJson;
    }
    this.setState({
      eligibleclients: filtergeoJson,
    });
  };

  saveEligibleZone = () => {
    let seteligibleZonesKeys = [...this.state.seteligibleZonesKeys];
    let eligibleKeys = _.uniqBy(seteligibleZonesKeys, "zoneKey");
    let objectFormation = {
      userZones: eligibleKeys,
    };
    this.props.saveZones(this.state.userId, objectFormation).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        notification["success"]({
          message: "SUCCESS",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.handleOk();
        this.setState({
          visibleZones: false,
          seteligibleZonesKeys: [],
        });
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.setState({
          visibleZones: false,
          seteligibleZonesKeys: [],
        });
      }
    });
  };

  saveEligibleClient = () => {
    let seteligibleClientsKeys = [...this.state.seteligibleClientsKeys];
    let eligibleKeys = _.uniqBy(seteligibleClientsKeys, "customerCode");
    let objectFormation = {
      taggedCustomers: eligibleKeys,
      action: "SAVE_TAGGED_CUSTOMER",
    };
    this.props.saveClients(this.state.userId, objectFormation).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        notification["success"]({
          message: "SUCCESS",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.handleOk();
        this.setState({
          visibleClients: false,
          seteligibleClientsKeys: [],
        });
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight",
        });
        this.setState({
          visibleClients: false,
          seteligibleClientsKeys: [],
        });
      }
    });
  };

  zoneEligibleCancel = () => {
    this.setState({
      visibleZones: false,
    });
  };

  clientEligibleCancel = () => {
    this.setState({
      visibleClients: false,
    });
  };

  setEligibleZone = (item) => {
    let count = 0;
    let seteligibleZones = [...this.state.eligibleZones];
    let seteligibleZonesKeys = [...this.state.seteligibleZonesKeys];
    seteligibleZones.map((x) => {
      if (item.zoneKey === x.zoneKey) {
        x.isTagged = !x.isTagged;
        seteligibleZonesKeys.push(item);
      }
    });
    seteligibleZones.map((x) => {
      if (x.isTagged) {
        count++;
      }
    });
    let allchecked = this.checkAllStatus2(seteligibleZones);
    this.setState({
      seteligibleZonesKeys: seteligibleZonesKeys,
      eligibleZones: seteligibleZones,
      checkallZone: allchecked,
      selectedCountZone: count,
    });
  };

  setEligibleClients = (item) => {
    let count = 0;
    let eligibleclients = [...this.state.eligibleclients];
    let seteligibleClientsKeys = [...this.state.seteligibleClientsKeys];
    eligibleclients.map((x) => {
      if (item.customerCode === x.customerCode) {
        x.isTagged = !x.isTagged;
        seteligibleClientsKeys.push(item);
      }
    });
    eligibleclients.map((x) => {
      if (x.isTagged) {
        count++;
      }
    });
    let allchecked = this.checkAllStatus2(eligibleclients);
    this.setState({
      seteligibleClientsKeys: seteligibleClientsKeys,
      eligibleclients: eligibleclients,
      checkallClient: allchecked,
      selectedCountClient: count,
    });
  };

  checkAllStatus2 = (data) => {
    let isSame = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].isTagged === false) {
        isSame = false;
        break;
      }
    }
    return isSame;
  };

  getCountOf = () => {
    let zonedata = [...this.state.zoneData];
    let count = 0;
    if (zonedata.length > 0) {
      zonedata.map((x) => {
        if (x.status === true) {
          count++;
        }
      });
    }
    return count;
  };

  getCountOfClient = () => {
    let clientData = [...this.state.clientData];
    let count = 0;
    if (clientData.length > 0) {
      clientData.map((x) => {
        if (x.status === true) {
          count++;
        }
      });
    }
    return count;
  };

  checkAllStatus = (data) => {
    let isSame = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === false) {
        isSame = false;
        break;
      }
    }
    return isSame;
  };

  getZones = () => {
    let payload = {
      cityId: this.state?.cityId,
      locationIds: this.state?.locationIds,
    };
    if (this.state?.zoneData) {
      let zoneKeys = [...this.state.seteligibleBoxZonesKeys];
      let allchecked = this.checkAllStatus(this.state?.zoneData);
      let getCountOf = this.getCountOf();
      this.setState({
        visibleboxZones: true,
        seteligibleBoxZonesKeys: zoneKeys,
        copyKeys: zoneKeys,
        checkallBoxZone: allchecked,
        selectedBoxCountZone: getCountOf,
        initalZoneFilter: {
          taggedZone: [{ id: 0, name: "None", status: this.state.zeroValue }],
        },
      });
    } else {
      this.props.getZoneData(payload);
      this.setState({
        visibleboxZones: true,
        checkallBoxZone: false,
        initalZoneFilter: {
          taggedZone: [{ id: 0, name: "None", status: this.state.zeroValue }],
        },
      });
    }
  };

  getClients = () => {
    if (this.state?.clientData) {
      let zoneKeys = [...this.state.seteligibleBoxClientsKeys];
      let allchecked = this.checkAllStatus(this.state?.clientData);
      let getCountOfClient = this.getCountOfClient();
      this.setState({
        visibleboxClients: true,
        seteligibleBoxClientsKeys: zoneKeys,
        copyKeys: zoneKeys,
        checkallBoxClient: allchecked,
        selectedBoxCountClient: getCountOfClient,
        initalClientFilter: {
          taggedClient: [{ id: 0, code: "None", status: this.state.zeroValue }],
        },
      });
    } else {
      this.props.getCustomers();
      this.setState({
        visibleboxClients: true,
        checkallBoxClient: false,
        initalClientFilter: {
          taggedClient: [
            { id: 0, code: "None", status: this.state.zeroValueClient },
          ],
        },
      });
    }
  };

  setEligibleBoxZone = (item) => {
    let count = 0;
    let zonedata = [...this.state.zoneData];
    let seteligibleBoxZonesKeys = this.state?.searchListApplied
      ? [...this.state.seteligibleBoxZonesKeys]
      : [];
    zonedata.map((x) => {
      if (item._key === x._key) {
        x.status = !x.status;
      }
    });
    zonedata.map((x) => {
      if (x.status === true) {
        seteligibleBoxZonesKeys.push(x);
        count++;
      }
    });
    let allchecked = this.checkAllStatus(zonedata);
    this.setState({
      seteligibleBoxZonesKeys: seteligibleBoxZonesKeys,
      zoneData: zonedata,
      checkallBoxZone: allchecked,
      selectedBoxCountZone: count,
    });
  };

  setEligibleBoxClient = (item) => {
    let count = 0;
    let clientData = [...this.state.clientData];
    let seteligibleBoxClientsKeys = this.state?.searchListAppliedClient
      ? [...this.state.seteligibleBoxClientsKeys]
      : [];
    clientData.map((x) => {
      if (item.code === x.code) {
        x.status = !x.status;
      }
    });
    clientData.map((x) => {
      if (x.status === true) {
        seteligibleBoxClientsKeys.push(x);
        count++;
      }
    });
    let allchecked = this.checkAllStatus(clientData);
    this.setState({
      seteligibleBoxClientsKeys: seteligibleBoxClientsKeys,
      clientData: clientData,
      checkallBoxClient: allchecked,
      selectedBoxCountClient: count,
    });
  };

  saveboxZone = (value) => {
    let seteligibleBoxZonesKeys = value
      ? this.state?.copyKeys
      : [...this.state?.seteligibleBoxZonesKeys];
    let eligibleKeys = _.uniqBy(seteligibleBoxZonesKeys, "_key");
    let id = 0;
    let setZoneFilter = { ...this.state.initalZoneFilter };
    let startArray = [];
    startArray.push({
      id: id++,
      name: "None",
      status: this.state.zeroValue,
    });
    setZoneFilter = { taggedZone: startArray };
    eligibleKeys.map((x) => {
      if (x.status === true) {
        setZoneFilter.taggedZone.push({
          id: id++,
          name: x._key,
          status: true,
        });
      }
    });

    this.setState({
      visibleboxZones: false,
      initalZoneFilter: setZoneFilter,
      seteligibleBoxZonesKeys: seteligibleBoxZonesKeys,
    });
  };

  saveOnboxZone = () => {
    let seteligibleBoxZonesKeys = this.state?.copyKeys;
    let zonedata = [...this.state.zoneData];
    let eligibleKeys = _.uniqBy(seteligibleBoxZonesKeys, "_key");
    let id = 0;
    let setZoneFilter = { ...this.state.initalZoneFilter };
    let zoneArray = [];
    let startArray = [];
    startArray.push({
      id: id++,
      name: "None",
      status: this.state.zeroValue,
    });
    setZoneFilter = { taggedZone: startArray };
    eligibleKeys.map((x) => {
      zoneArray.push(x._key);
      setZoneFilter.taggedZone.push({
        id: id++,
        name: x._key,
        status: true,
      });
    });
    zonedata.map((x) => {
      if (zoneArray.includes(x._key)) {
        x.status = true;
      }
    });
    this.setState({
      visibleboxZones: false,
      initalZoneFilter: setZoneFilter,
      zoneData: zonedata,
      seteligibleBoxZonesKeys: seteligibleBoxZonesKeys,
    });
  };

  zoneboxCancel = () => {
    let zonedata = [...this.state.zoneData];
    let eligibleKeys = _.uniqBy(this.state.copyKeys, "_key");
    let keysArray = [];
    eligibleKeys.map((y) => {
      keysArray.push(y._key);
    });
    zonedata.map((x) => {
      if (!keysArray.includes(x._key)) {
        x.status = false;
      }
    });
    this.saveOnboxZone();
    this.setState({
      visibleboxZones: false,
      zonedata: zonedata,
    });
  };

  saveboxClient = (value) => {
    let seteligibleBoxClientsKeys = value
      ? this.state?.copyKeys
      : [...this.state?.seteligibleBoxClientsKeys];
    let eligibleKeys = _.uniqBy(seteligibleBoxClientsKeys, "code");
    let id = 0;
    let setClientFilter = { ...this.state.initalClientFilter };
    let startArray = [];
    startArray.push({
      id: id++,
      code: "None",
      status: this.state.zeroValueClient,
    });
    setClientFilter = { taggedClient: startArray };
    eligibleKeys.map((x) => {
      if (x.status === true) {
        setClientFilter.taggedClient.push({
          id: id++,
          code: x.code,
          status: true,
        });
      }
    });

    this.setState({
      visibleboxClients: false,
      initalClientFilter: setClientFilter,
      seteligibleBoxClientsKeys: seteligibleBoxClientsKeys,
    });
  };

  saveOnboxClient = () => {
    let seteligibleBoxClientsKeys = this.state?.copyKeys;
    let clientData = [...this.state.clientData];
    let eligibleKeys = _.uniqBy(seteligibleBoxClientsKeys, "code");
    let id = 0;
    let setClientFilter = { ...this.state.initalClientFilter };
    let clientArray = [];
    let startArray = [];
    startArray.push({
      id: id++,
      code: "None",
      status: this.state.zeroValueClient,
    });
    setClientFilter = { taggedClient: startArray };
    eligibleKeys.map((x) => {
      clientArray.push(x.code);
      setClientFilter.taggedClient.push({
        id: id++,
        code: x.code,
        status: true,
      });
    });
    clientData.map((x) => {
      if (clientArray.includes(x._key)) {
        x.status = true;
      }
    });
    this.setState({
      visibleboxClients: false,
      initalClientFilter: setClientFilter,
      clientData: clientData,
      seteligibleBoxClientsKeys: seteligibleBoxClientsKeys,
    });
  };

  clientboxCancel = () => {
    let clientData = [...this.state.clientData];
    let eligibleKeys = _.uniqBy(this.state.copyKeys, "code");
    let keysArray = [];
    eligibleKeys.map((y) => {
      keysArray.push(y.code);
    });
    clientData.map((x) => {
      if (!keysArray.includes(x.code)) {
        x.status = false;
      }
    });
    this.saveOnboxClient();
    this.setState({
      visibleboxClients: false,
      clientData: clientData,
    });
  };

  checkallZonesBox = (e) => {
    let zoneData = [...this.state.zoneData];
    if (e.target.checked === true) {
      zoneData.map((x) => {
        x.status = true;
      });
      this.setState({
        zoneData: zoneData,
        checkallBoxZone: true,
        seteligibleBoxZonesKeys: zoneData,
        selectedBoxCountZone: zoneData.length,
      });
    } else {
      zoneData.map((x) => {
        x.status = false;
      });
      this.setState({
        zoneData: zoneData,
        checkallBoxZone: false,
        seteligibleBoxZonesKeys: [],
        selectedBoxCountZone: 0,
      });
    }
  };

  checkallClientssBox = (e) => {
    let clientData = [...this.state.clientData];
    if (e.target.checked === true) {
      clientData.map((x) => {
        x.status = true;
      });
      this.setState({
        clientData: clientData,
        checkallBoxClient: true,
        seteligibleBoxClientsKeys: clientData,
        selectedBoxCountClient: clientData.length,
      });
    } else {
      clientData.map((x) => {
        x.status = false;
      });
      this.setState({
        clientData: clientData,
        checkallBoxClient: false,
        seteligibleBoxClientsKeys: [],
        selectedBoxCountClient: 0,
      });
    }
  };

  checkallZones = (e) => {
    let eligibleZones = [...this.state.eligibleZones];
    if (e.target.checked === true) {
      eligibleZones.map((x) => {
        x.isTagged = true;
      });
      this.setState({
        eligibleZones: eligibleZones,
        checkallZone: true,
        seteligibleZonesKeys: eligibleZones,
        selectedCountZone: eligibleZones.length,
      });
    } else {
      eligibleZones.map((x) => {
        x.isTagged = false;
      });
      this.setState({
        eligibleZones: eligibleZones,
        checkallZone: false,
        seteligibleZonesKeys: eligibleZones,
        selectedCountZone: 0,
      });
    }
  };

  checkallClients = (e) => {
    let eligibleclients = [...this.state.eligibleclients];
    if (e.target.checked === true) {
      eligibleclients.map((x) => {
        x.isTagged = true;
      });
      this.setState({
        eligibleclients: eligibleclients,
        checkallClient: true,
        seteligibleClientsKeys: eligibleclients,
        selectedCountClient: eligibleclients.length,
      });
    } else {
      eligibleclients.map((x) => {
        x.isTagged = false;
      });
      this.setState({
        eligibleclients: eligibleclients,
        checkallClient: false,
        seteligibleClientsKeys: eligibleclients,
        selectedCountClient: 0,
      });
    }
  };

  setLocationEndData = (data) => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach((x) => {
        endLocationsData.push(
          <Option key={x.id} value={x.id} label={x.name}>
            {x.name}
          </Option>
        );
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  };

  setLocationpartnerData = (data) => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach((x) => {
        endLocationsData.push(
          <Option key={x.id} value={x.id} label={x.alias}>
            {x.alias}
          </Option>
        );
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  };

  openBulkEligibleModal = () => {
    if (this.state.locationIds.length === 0) {
      return;
    }
    const payload = {
      locationIds: this.state.locationIds,
    };
    const self = this;
    this.setState(
      {
        bulkElInelModal: true,
        bulkElInelModalLoading: true,
      },
      () => {
        self.props.getMultiTaggedEligibility(payload).then((resp) => {
          if (
            resp?.data?.response?.inEligibleFes &&
            resp?.data?.response?.eligibleFes
          ) {
            self.setState({
              inEligibleFes: resp.data.response.inEligibleFes.map((item) => ({
                key: item.userId,
                title: `${item.name} (${item?.contactNumber})`,
                ...item,
              })),
              eligibleFes: resp.data.response.eligibleFes.map((item) => ({
                key: item.userId,
                title: `${item.name} (${item?.contactNumber})`,
                ...item,
              })),
              eligibleFesTargets: resp.data.response.eligibleFes.map(
                (item) => item.userId
              ),
              bulkElInelModalLoading: false,
            });
          }
        });
      }
    );
  };

  handleBulkElModalChange = (eligibleFesTargets, direction, moveKeys) => {
    const whichArr = direction === "left" ? "inEligibleFes" : "eligibleFes";
    const whichNonArr = !(direction === "left")
      ? "inEligibleFes"
      : "eligibleFes";
    debugger;
    const removedData = this.state.bulkElInelModalSelectedKey[
      whichNonArr
    ].filter((item) => !moveKeys.includes(item));
    moveKeys = moveKeys.filter(
      (item) => !this.state[whichArr].find((fe) => fe.userId === item)
    );

    this.setState((state) => ({
      eligibleFesTargets,
      isBulkEligibleModiified: true,
      bulkElInelModalSelectedKey: {
        ...this.state.bulkElInelModalSelectedKey,
        [whichArr]: [
          ...state.bulkElInelModalSelectedKey[whichArr],
          ...moveKeys,
        ],
        [whichNonArr]: removedData,
      },
    }));
  };

  handleBulkElModalSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    const stateValue = [
      ...this.state.bulkElInelModalSelectedKey.inEligibleFes,
      ...this.state.bulkElInelModalSelectedKey.eligibleFes,
    ];
    const combinedKeys = [
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
      ...stateValue,
    ];
    if (
      combinedKeys.length > ITEM_SELECTION_LIMIT ||
      this.state.eligibleFesSelectedKeys.length > ITEM_SELECTION_LIMIT
    ) {
      utils.notifyApiError("Item selection limit reached");
      return false;
    }
    this.setState({
      eligibleFesSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleBulkElModalSave = () => {
    this.setState({
      isBulkEligibleSaving: true,
    });
    const payload = {
      eligibleUserIds: this.state.bulkElInelModalSelectedKey.eligibleFes,
      ineligibleUserIds: this.state.bulkElInelModalSelectedKey.inEligibleFes,
    };
    this.props.saveMultiTaggedEligibility(payload).then((resp) => {
      if (resp.data?.response?.sync) {
        utils.notifyApiSuccess("Successfully changed");
        this.setState(
          {
            bulkElInelModal: false,
            bulkElInelModalSelectedKey: {
              eligibleFes: [],
              inEligibleFes: [],
            },
            isBulkEligibleSaving: false,
          },
          () => this.reloadList()
        );
      } else {
        utils.notifyApiError("Failed to changes, Please try again.");
      }
    });
  };

  openCODModal = () => {
    if (this.state.locationIds.length === 0) {
      return;
    }
    const payload = {
      locationIds: this.state.locationIds,
    };
    const self = this;
    this.setState(
      {
        codModal: true,
        codModalLoading: true,
      },
      () => {
        self.props.getCodEligibility(payload).then((resp) => {
          if (
            resp?.data?.response?.codInEligibleFes &&
            resp?.data?.response?.codEligibleFes
          ) {
            self.setState({
              codInEligibleFes: resp.data.response.codInEligibleFes.map(
                (item) => ({
                  key: item.userId,
                  title: `${item.name} (${item?.contactNumber})`,
                  ...item,
                  disabled: !this.state.fleetPermission
                    .cod_enable_fe_permission,
                })
              ),
              codEligibleFes: resp.data.response.codEligibleFes.map((item) => ({
                key: item.userId,
                title: `${item.name} (${item?.contactNumber})`,
                ...item,
              })),
              codEligibleFesTargets: resp.data.response.codEligibleFes.map(
                (item) => item.userId
              ),
              codModalLoading: false,
            });
          }
        });
      }
    );
  };

  handleCodModalChange = (codEligibleFesTargets, direction, moveKeys) => {
    const whichArr =
      direction === "left" ? "codInEligibleFes" : "codEligibleFes";
    const whichNonArr = !(direction === "left")
      ? "codInEligibleFes"
      : "codEligibleFes";

    const removedData = this.state.codModalSelectedKey[whichNonArr].filter(
      (item) => !moveKeys.includes(item)
    );
    moveKeys = moveKeys.filter(
      (item) => !this.state[whichArr].find((fe) => fe.userId === item)
    );

    this.setState((state) => ({
      codEligibleFesTargets,
      isCodListModiified: true,
      codModalSelectedKey: {
        ...this.state.codModalSelectedKey,
        [whichArr]: [...state.codModalSelectedKey[whichArr], ...moveKeys],
        [whichNonArr]: removedData,
      },
    }));
  };

  handleBulkCodModalSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    const stateValue = [
      ...this.state.codModalSelectedKey.codInEligibleFes,
      ...this.state.codModalSelectedKey.codEligibleFes,
    ];
    const combinedKeys = [
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
      ...stateValue,
    ];
    debugger;
    if (
      combinedKeys.length > ITEM_SELECTION_LIMIT ||
      this.state.codEligibleSelectedKeys.length > ITEM_SELECTION_LIMIT
    ) {
      utils.notifyApiError("Item selection limit reached");
      return false;
    }
    this.setState({
      codEligibleSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleCodModalSave = () => {
    this.setState({
      isCodDataSaving: true,
    });
    const payload = {
      codEligibleUserIds: this.state.codModalSelectedKey.codEligibleFes,
      codIneligibleUserIds: this.state.codModalSelectedKey.codInEligibleFes,
    };
    this.props.saveCodEligibility(payload).then((resp) => {
      if (resp.data?.response?.sync) {
        utils.notifyApiSuccess("Successfully changed");
        this.setState(
          {
            codModal: false,
            codModalSelectedKey: {
              codEligibleFes: [],
              codInEligibleFes: [],
            },
            isCodDataSaving: false,
          },
          () => this.reloadList()
        );
      } else {
        utils.notifyApiError("Failed to changes, Please try again.");
      }
    });
  };

  openBulkZonesModal = () => {
    this.handleBulkZoneReset();
    this.setState({
      bulkZoneTagModal: true,
    });
  };

  handleApplyBulkZonesModal = () => {
    if (
      this.state.locationIds.length === 0 &&
      !this.state.bulkZoneTagModalSelectedZone
    ) {
      return;
    }
    const payload = {
      locationIds: this.state.locationIds,
      taggedZone: this.state.bulkZoneTagModalSelectedZone,
    };
    const self = this;
    this.setState(
      {
        bulkZoneTagModal: true,
      },
      () => {
        self.props.getMultiTaggedZones(payload).then((resp) => {
          if (
            resp?.data?.response?.untaggedZoneFes &&
            resp?.data?.response?.taggedZoneFes
          ) {
            self.setState({
              untaggedZoneFes: resp.data.response.untaggedZoneFes.map(
                (item) => ({
                  key: item.userId,
                  title: `${item.name} (${item?.contactNumber})`,
                  ...item,
                })
              ),
              taggedZoneFes: resp.data.response.taggedZoneFes.map((item) => ({
                key: item.userId,
                title: `${item.name} (${item?.contactNumber})`,
                ...item,
              })),
              taggedZoneFesTargets: resp.data.response.taggedZoneFes.map(
                (item) => item.userId
              ),
              bulkElInelModalLoading: false,
            });
          }
        });
      }
    );
  };

  handleBulkZoneModalChange = (taggedZoneFesTargets, direction, moveKeys) => {
    const whichArr = direction === "left" ? "untaggedZoneFes" : "taggedZoneFes";
    const whichNonArr = !(direction === "left")
      ? "untaggedZoneFes"
      : "taggedZoneFes";

    const removedData = this.state.bulkZoneTagModalSelectedKey[
      whichNonArr
    ].filter((item) => !moveKeys.includes(item));
    moveKeys = moveKeys.filter(
      (item) => !this.state[whichArr].find((fe) => fe.userId === item)
    );

    this.setState((state) => ({
      taggedZoneFesTargets,
      isBulkZoneTaggedModiified: true,
      bulkZoneTagModalSelectedKey: {
        ...this.state.bulkZoneTagModalSelectedKey,
        [whichArr]: [
          ...state.bulkZoneTagModalSelectedKey[whichArr],
          ...moveKeys,
        ],
        [whichNonArr]: removedData,
      },
    }));
  };

  handleBulkZoneModalSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    const stateValue = [
      ...this.state.bulkZoneTagModalSelectedKey.untaggedZoneFes,
      ...this.state.bulkZoneTagModalSelectedKey.taggedZoneFes,
    ];
    const combinedKeys = [
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
      ...stateValue,
    ];
    if (
      combinedKeys.length > ITEM_SELECTION_LIMIT ||
      this.state.bulkZoneTagsSelectedKeys.length > ITEM_SELECTION_LIMIT
    ) {
      utils.notifyApiError("Item selection limit reached");
      return false;
    }
    this.setState({
      bulkZoneTagsSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleBulkZoneReset = () => {
    this.setState({
      bulkZoneTagModalSelectedZone: undefined,
      bulkZoneTagModalSelectedKey: {
        untaggedZoneFes: [],
        taggedZoneFes: [],
      },
      untaggedZoneFes: [],
      taggedZoneFes: [],
    });
  };

  handleBulkZoneModalSave = () => {
    this.setState({
      isBulkZoneTaggedSaving: true,
    });
    const payload = {
      taggedZoneUserIds: this.state.bulkZoneTagModalSelectedKey.taggedZoneFes,
      untaggedZoneUserIds: this.state.bulkZoneTagModalSelectedKey
        .untaggedZoneFes,
      userZone: this.props.allZones.find(
        (zone) => zone.zoneKey === this.state.bulkZoneTagModalSelectedZone
      ),
    };
    this.props.saveMultiTaggedZones(payload).then((resp) => {
      if (resp.data?.response?.sync) {
        utils.notifyApiSuccess("Successfully changed");
        this.setState(
          {
            bulkZoneTagModal: false,
            bulkZoneTagModalSelectedZone: undefined,
            bulkZoneTagModalSelectedKey: {
              untaggedZoneFes: [],
              taggedZoneFes: [],
            },
            untaggedZoneFes: [],
            taggedZoneFes: [],
            isBulkZoneTaggedSaving: false,
          },
          () => {
            this.reloadList();
          }
        );
      } else {
        utils.notifyApiError("Failed to changes, Please try again.");
      }
    });
  };

  openBulkClientModal = () => {
    this.handleBulkClientReset();
    this.setState({
      bulkClientTagModal: true,
    });
  };

  handleApplyBulkClientModal = () => {
    if (
      this.state.locationIds.length === 0 &&
      !this.state.bulkClientTagModalSelectedClient
    ) {
      return;
    }
    const payload = {
      locationIds: this.state.locationIds,
      taggedCustomerCode: this.state.bulkClientTagModalSelectedClient,
    };
    const self = this;
    this.setState(
      {
        bulkClientTagModalLoading: true,
      },
      () => {
        self.props.getMultiTaggedCustomers(payload).then((resp) => {
          if (
            resp?.data?.response?.untaggedCustomerFes &&
            resp?.data?.response?.taggedCustomerFes
          ) {
            self.setState({
              untaggedCustomerFes: resp.data.response.untaggedCustomerFes.map(
                (item) => ({
                  key: item.userId,
                  title: `${item.name} (${item?.contactNumber})`,
                  ...item,
                })
              ),
              taggedCustomerFes: resp.data.response.taggedCustomerFes.map(
                (item) => ({
                  key: item.userId,
                  title: `${item.name} (${item?.contactNumber})`,
                  ...item,
                })
              ),
              taggedCustomerFesTargets: resp.data.response.taggedCustomerFes.map(
                (item) => item.userId
              ),
              bulkClientTagModalLoading: false,
            });
          }
        });
      }
    );
  };

  handleBulkClientModalChange = (
    taggedCustomerFesTargets,
    direction,
    moveKeys
  ) => {
    const whichArr =
      direction === "left" ? "untaggedCustomerFes" : "taggedCustomerFes";
    const whichNonArr = !(direction === "left")
      ? "untaggedCustomerFes"
      : "taggedCustomerFes";

    const removedData = this.state.bulkClientTagModalSelectedKey[
      whichNonArr
    ].filter((item) => !moveKeys.includes(item));
    moveKeys = moveKeys.filter(
      (item) => !this.state[whichArr].find((fe) => fe.userId === item)
    );

    this.setState((state) => ({
      taggedCustomerFesTargets,
      isBulkClientTaggedModiified: true,
      bulkClientTagModalSelectedKey: {
        ...this.state.bulkClientTagModalSelectedKey,
        [whichArr]: [
          ...state.bulkClientTagModalSelectedKey[whichArr],
          ...moveKeys,
        ],
        [whichNonArr]: removedData,
      },
    }));
  };

  handleBulkClientModalSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    const stateValue = [
      ...this.state.bulkClientTagModalSelectedKey.untaggedCustomerFes,
      ...this.state.bulkClientTagModalSelectedKey.taggedCustomerFes,
    ];
    const combinedKeys = [
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
      ...stateValue,
    ];
    debugger;
    if (
      combinedKeys.length > ITEM_SELECTION_LIMIT ||
      this.state.taggedClientSelectedKeys.length > ITEM_SELECTION_LIMIT
    ) {
      utils.notifyApiError("Item selection limit reached");
      return false;
    }
    this.setState({
      taggedClientSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleBulkClientReset = () => {
    this.setState({
      bulkClientTagModalSelectedClient: undefined,
      bulkClientTagModalSelectedKey: {
        untaggedCustomerFes: [],
        taggedCustomerFes: [],
      },
      untaggedCustomerFes: [],
      taggedCustomerFes: [],
      taggedCustomerFesTargets: [],
    });
  };

  handleBulkClientModalSave = () => {
    this.setState({
      isBulkClientTaggedSaving: true,
    });
    const payload = {
      taggedCustomerUserIds: this.state.bulkClientTagModalSelectedKey
        .taggedCustomerFes,
      untaggedCustomerUserIds: this.state.bulkClientTagModalSelectedKey
        .untaggedCustomerFes,
      taggedCustomer: {
        customerCode: this.state.bulkClientTagModalSelectedClient,
      },
    };
    this.props.saveMultiTaggedCustomers(payload).then((resp) => {
      if (resp.data?.response?.sync) {
        utils.notifyApiSuccess("Successfully changed");
        this.setState(
          {
            bulkClientTagModal: false,
            isBulkClientTaggedSaving: false,
          },
          () => {
            this.handleBulkClientReset();
            this.reloadList();
          }
        );
      } else {
        utils.notifyApiError("Failed to changes, Please try again.");
      }
    });
  };

  showDeliveryHandler = (value) => {
    this.setState({
      showDeliveryHistory: true,
      selectedNumber: value,
    });
  };

  closeDeliveryHistory = () => {
    this.setState({
      showDeliveryHistory: false,
      selectedNumber: undefined,
    });
  };

  // apiRequest({
  //   url: `/hyperlocal/v2/order/reAllocationReasons`,
  //   method: "GET",
  // })
  // .then(res => {
  //   if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
  //     console.log("res", res.data.response.orderCancellationReasons)
  //  let categories = _.groupBy(res?.data?.response?.orderCancellationReasons, "reasonCategoryText");
  toggleIneligibilityHandler = () => {
    this.setState({
      isToggleModalVisible: false,
      isToggleIneligibleModalVisible: true,
      isToggleModalConfirmButton: false,
      toggleReasonError: undefined,
      isToggleIneligibilityReasonSubmitted: false,
      toggleIneligibilitySelectedReason: undefined,
    });
  };

  toggleEligibilityHandler = () => {
    this.setState({
      isToggleIneligibleModalVisible: false, //big popup
      isToggleModalVisible: true,
      isToggleModalConfirmButton: false, //disables submit button
      toggleReasonError: undefined,
      isToggleReasonSubmitted: false,
      toggleSelectedReason: undefined,
    });
  };

  handleToggleModalCancel = () => {
    this.setState({
      isToggleModalVisible: false,
      toggleReasonError: undefined,
      toggleReasonCategories: {},
      isToggleModalConfirmButton: false,
      toggleSelectedReason: undefined,
      toggleIneligibilitySelectedReason: undefined,
      isToggleIneligibleModalVisible: false,
    });
  };

  toggleChangeCategories = (e) => {
    this.setState({
      toggleSelectedReason: e.target.value,
      toggleIneligibilitySelectedReason: e.target.value,
      isToggleModalConfirmButton: true,
    });
  };

  handleToggleSubmit = () => {
    let payload = {};
    console.log("isToggleCheck from handlesubmit", this.state.isToggleCheck);
    if (this.state.isToggleCheck) {
      //small pop up
      this.handleToggleModalCancel();
      this.setState({
        isToggleModalConfirmButton: true,
        isToggleReasonSubmitted: true,
      });
      payload = {
        userRequestList: [
          {
            userId: this.state.rowId,
            action: "MARK_ELIGIBLE_FOR_HYPERLOCAL",
            reason: this.state.toggleSelectedReason,
          },
        ],
      };
      this.props.setPermissions(payload);
    } else {
      //big pop up
      this.handleToggleModalCancel();
      this.setState({
        isToggleModalConfirmButton: true,
        isToggleIneligibilityReasonSubmitted: true,
      });
      payload = {
        userRequestList: [
          {
            userId: this.state.rowId,
            action: "MARK_INELIGIBLE_FOR_HYPERLOCAL",
            reason: this.state.toggleIneligibilitySelectedReason,
          },
        ],
      };
      this.props.setPermissions(payload);
    }
  };

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };

    const bulkCodELInelModalDisabled =
      [
        ...this.state.codModalSelectedKey.codInEligibleFes,
        ...this.state.codModalSelectedKey.codEligibleFes,
      ].length >= ITEM_SELECTION_LIMIT;
    const bulkElInelModalDisabled =
      [
        ...this.state.bulkElInelModalSelectedKey?.inEligibleFes,
        ...this.state.bulkElInelModalSelectedKey?.eligibleFes,
      ].length >= ITEM_SELECTION_LIMIT;
    const bulkZoneModalDisabled =
      [
        ...this.state.bulkZoneTagModalSelectedKey?.untaggedZoneFes,
        ...this.state.bulkZoneTagModalSelectedKey?.taggedZoneFes,
      ].length >= ITEM_SELECTION_LIMIT;
    const bulkClientModalDisabled =
      [
        ...this.state.bulkClientTagModalSelectedKey?.untaggedCustomerFes,
        ...this.state.bulkClientTagModalSelectedKey?.taggedCustomerFes,
      ].length >= ITEM_SELECTION_LIMIT;

    return (
      <>
        <Card title="Manage FE" className="">
          <Row gutter={{ xs: 8, sm: 16, md: 18, lg: 18 }}>
            <Col span={2}>
              <Button
                block
                onClick={this.showModal}
                disabled={this.state?.users ? false : true}
              >
                Filter
              </Button>
            </Col>
            <Col span={2}>
              <Tooltip placement="top" title={"Reload"}>
                <Button
                  onClick={this.reloadList}
                  type="primary"
                  style={{ marginLeft: 2, float: "left" }}
                  disabled={this.state?.users ? false : true}
                  loading={this.props.fleetStatus?.loading}
                >
                  <Icon type="reload" />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 18, lg: 18 }}
            style={{ marginTop: 20 }}
          >
            {/* <Col span={8}>
              <Col className="gutter-row" span={7}></Col>
            </Col> */}
            <Col span={6}>
              <Input.Search
                placeholder="Search"
                allowClear
                ref={this.InputSearchRef}
                onChange={(e) => this.userSearch(e)}
                disabled={this.state?.users ? false : true}
                onSearch={(value) =>
                  this.applySearch({
                    partialContentSearch: value.toLowerCase().trim(),
                  })
                }
                enterButton
              />
            </Col>
            <Col span={4} offset={2}>
              <Tooltip
                placement="top"
                title={
                  this.state.fleetPermission.cod_permission
                    ? ""
                    : "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                }
              >
                <Button
                  block
                  disabled={
                    !this.state?.users ||
                    !this.state.fleetPermission.cod_permission
                  }
                  type="primary"
                  onClick={this.openCODModal}
                >
                  COD Eligible/Ineligible
                </Button>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip
                placement="top"
                title={
                  this.state.fleetPermission.bulk_permission
                    ? ""
                    : "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                }
              >
                <Button
                  block
                  disabled={
                    !this.state?.users ||
                    !this.state.fleetPermission.bulk_permission
                  }
                  type="primary"
                  onClick={this.openBulkEligibleModal}
                >
                  Bulk Eligible/Ineligible
                </Button>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip
                placement="top"
                title={
                  this.state.fleetPermission.bulk_zone_tagging_permission
                    ? ""
                    : "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                }
              >
                <Button
                  block
                  disabled={
                    !this.state?.users ||
                    !this.state.fleetPermission.bulk_zone_tagging_permission
                  } //{this.state?.users ? false : true}
                  type="primary"
                  onClick={this.openBulkZonesModal}
                >
                  Bulk Zone Tagging
                </Button>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip
                placement="top"
                title={
                  this.state.fleetPermission.bulk_client_tagging_permission
                    ? ""
                    : "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                }
              >
                <Button
                  block
                  disabled={
                    !this.state?.users ||
                    !this.state.fleetPermission.bulk_client_tagging_permission
                  } //{this.state?.users ? false : true}
                  type="primary"
                  onClick={this.openBulkClientModal}
                >
                  Bulk Client Tagging
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ marginTop: 20 }}
          >
            <Col className="gutter-row" span={24}>
              <Table
                bordered
                locale={{
                  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                }}
                loading={this.props.fleetStatus?.loading}
                dataSource={this.state.users}
                columns={this.state.columns}
                scroll={{ x: 1250 }}
              />
            </Col>
          </Row>
          <Row>
            <Modal
              title="Select Client"
              visible={this.state.bulkClientTagModal}
              onOk={this.handleBulkClientModalSave}
              onCancel={(e) => {
                this.setState({
                  bulkClientTagModal: false,
                  bulkClientTagModalSelectedClient: "",
                  bulkClientTagModalSelectedKey: {
                    untaggedCustomerFes: [],
                    taggedCustomerFes: [],
                  },
                  untaggedCustomerFes: [],
                  taggedCustomerFes: [],
                  taggedCustomerFesTargets: [],
                });
              }}
              maskClosable={false}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleBulkClientModalSave}
                  disabled={!this.state.isBulkClientTaggedModiified}
                  loading={this.state.isBulkClientTaggedSaving}
                >
                  Save
                </Button>,
              ]}
              width={720}
            >
              <Spin spinning={this.state.bulkClientTagModalLoading}>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select Client"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={this.state.bulkClientTagModalSelectedClient}
                      onChange={(value) =>
                        this.setState({
                          untaggedCustomerFes: [],
                          taggedCustomerFes: [],
                          bulkClientTagModalSelectedClient: value,
                        })
                      }
                    >
                      {this.props?.allHyperlocalCustomers?.length &&
                        this.props?.allHyperlocalCustomers?.map((zone) => {
                          return (
                            <Option value={zone.customerCode}>
                              {zone.customerCode}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                  <Col span={4}>
                    <Button
                      key="submit"
                      type="default"
                      onClick={(e) => {
                        this.handleBulkClientReset();
                      }}
                      block
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      key="submit"
                      type="primary"
                      onClick={this.handleApplyBulkClientModal}
                      block
                    >
                      Apply
                    </Button>
                  </Col>
                  <Col span={24} className="transfer-list-item">
                    {bulkClientModalDisabled && (
                      <div style={{ marginBottom: "5px", color: "red" }}>
                        [NOTE: Item Modifierable limit reached of{" "}
                        {ITEM_SELECTION_LIMIT} Items per request. Please save
                        this reqest and try again for rest of the changes]
                      </div>
                    )}
                    <Transfer
                      dataSource={[
                        ...this.state?.untaggedCustomerFes,
                        ...this.state?.taggedCustomerFes,
                      ]}
                      targetKeys={this.state?.taggedCustomerFesTargets}
                      showSearch
                      disabled={bulkClientModalDisabled}
                      showSelectAll={false}
                      onChange={this.handleBulkClientModalChange}
                      selectedKeys={this.state?.taggedClientSelectedKeys}
                      onSelectChange={this.handleBulkClientModalSelectChange}
                      rowKey={(record) => record.userId}
                      titles={["Untagged Users", "Tagged Users"]}
                      listStyle={{
                        width: "45%",
                        height: 400,
                      }}
                      render={(item) => (
                        <>
                          {" "}
                          <span className="list-name"> {item.name} </span>{" "}
                          <br />{" "}
                          <span className="list-phone">
                            {" "}
                            {item.contactNumber}{" "}
                          </span>{" "}
                        </>
                      )}
                      filterOption={(inputValue, option) => {
                        let sep = inputValue.includes(",") ? "," : " ";
                        console.log("sepe", sep);
                        inputValue = inputValue
                          .split(sep)
                          .find((i) => option.title.includes(i));
                        return !!inputValue;
                      }}
                    />
                  </Col>
                </Row>
              </Spin>
            </Modal>
            <Modal
              title="Select Zone"
              visible={this.state.bulkZoneTagModal}
              onOk={this.handleBulkZoneModalSave}
              onCancel={(e) => {
                this.setState(
                  {
                    bulkZoneTagModal: false,
                  },
                  () => this.handleBulkZoneReset()
                );
              }}
              maskClosable={false}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleBulkZoneModalSave}
                  disabled={!this.state.isBulkZoneTaggedModiified}
                  loading={this.state.isBulkZoneTaggedSaving}
                >
                  Save
                </Button>,
              ]}
              width={720}
            >
              <Spin spinning={this.state.bulkZoneTagModalLoading}>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select Zone"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={this.state.bulkZoneTagModalSelectedZone}
                      onChange={(value) =>
                        this.setState({
                          untaggedZoneFes: [],
                          taggedZoneFes: [],
                          bulkZoneTagModalSelectedZone: value,
                        })
                      }
                    >
                      {this.props?.allZones?.length &&
                        this.props?.allZones?.map((zone) => {
                          return (
                            <Option value={zone.zoneKey}>
                              {zone.zoneName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                  <Col span={4}>
                    <Button
                      key="submit"
                      type="default"
                      onClick={(e) => {
                        this.handleBulkZoneReset();
                      }}
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      key="submit"
                      type="primary"
                      onClick={this.handleApplyBulkZonesModal}
                    >
                      Apply
                    </Button>
                  </Col>
                  <Col span={24} className="transfer-list-item">
                    {bulkZoneModalDisabled && (
                      <div style={{ marginBottom: "5px", color: "red" }}>
                        [NOTE: Item Modifierable limit reached of{" "}
                        {ITEM_SELECTION_LIMIT} Items per request. Please save
                        this reqest and try again for rest of the changes]
                      </div>
                    )}
                    <Transfer
                      dataSource={[
                        ...this.state?.untaggedZoneFes,
                        ...this.state?.taggedZoneFes,
                      ]}
                      targetKeys={this.state?.taggedZoneFesTargets}
                      showSearch
                      showSelectAll={false}
                      onChange={this.handleBulkZoneModalChange}
                      disabled={bulkZoneModalDisabled}
                      selectedKeys={this.state?.bulkZoneTagsSelectedKeys}
                      onSelectChange={this.handleBulkZoneModalSelectChange}
                      rowKey={(record) => record.userId}
                      titles={["Untagged Users", "Tagged Users"]}
                      listStyle={{
                        width: "45%",
                        height: 400,
                      }}
                      render={(item) => (
                        <>
                          {" "}
                          <span className="list-name"> {item.name} </span>{" "}
                          <br />{" "}
                          <span className="list-phone">
                            {" "}
                            {item.contactNumber}{" "}
                          </span>{" "}
                        </>
                      )}
                      filterOption={(inputValue, option) => {
                        let sep = inputValue.includes(",") ? "," : " ";
                        console.log("sepe", sep);
                        inputValue = inputValue
                          .split(sep)
                          .find((i) => option.title.includes(i));
                        return !!inputValue;
                      }}
                    />
                  </Col>
                </Row>
              </Spin>
            </Modal>
            <Modal
              title="Bulk Eligible/Ineligible"
              visible={this.state.bulkElInelModal}
              onOk={this.handleBulkElModalSave}
              onCancel={(e) => {
                this.setState({
                  bulkElInelModal: false,
                  bulkElInelModalSelectedKey: {
                    eligibleFes: [],
                    inEligibleFes: [],
                  },
                });
              }}
              maskClosable={false}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleBulkElModalSave}
                  disabled={!this.state.isBulkEligibleModiified}
                  loading={this.state.isBulkEligibleSaving}
                >
                  Save
                </Button>,
              ]}
              filterOption={(inputValue, option) => {
                let sep = inputValue.includes(",") ? "," : " ";
                console.log("sepe", sep);
                inputValue = inputValue
                  .split(sep)
                  .find((i) => option.title.includes(i));
                return !!inputValue;
              }}
              width={720}
            >
              <div span={24} className="transfer-list-item">
                <Spin spinning={this.state.bulkElInelModalLoading}>
                  {bulkElInelModalDisabled && (
                    <div style={{ marginBottom: "5px", color: "red" }}>
                      [NOTE: Item Modifierable limit reached of{" "}
                      {ITEM_SELECTION_LIMIT} Items per request. Please save this
                      reqest and try again for rest of the changes]
                    </div>
                  )}
                  <Transfer
                    dataSource={[
                      ...this.state?.inEligibleFes,
                      ...this.state?.eligibleFes,
                    ]}
                    targetKeys={this.state?.eligibleFesTargets}
                    selectedKeys={this.state?.eligibleFesSelectedKeys}
                    sourceSelectedKeys={
                      this.state.bulkElInelModalSelectedKey?.inEligibleFes
                    }
                    showSearch
                    showSelectAll={false}
                    onChange={this.handleBulkElModalChange}
                    disabled={bulkElInelModalDisabled}
                    onSelectChange={this.handleBulkElModalSelectChange}
                    rowKey={(record) => record.userId}
                    titles={["Ineligible Users", "Eligible Users"]}
                    listStyle={{
                      width: "45%",
                      height: 400,
                    }}
                    filterOption={(inputValue, option) => {
                      inputValue = inputValue
                        .split(" ")
                        .find((i) => option.title.includes(i));
                      return !!inputValue;
                    }}
                    render={(item) => (
                      <>
                        {" "}
                        <span className="list-name">
                          {" "}
                          {item.name}{" "}
                        </span> <br />{" "}
                        <span className="list-phone">
                          {" "}
                          {item.contactNumber}{" "}
                        </span>{" "}
                      </>
                    )}
                  />
                </Spin>
              </div>
            </Modal>
            <Modal
              title="Filters"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              className="fleetModalFooter"
              maskClosable={false}
              footer={[
                <Button key="back" onClick={this.resetFilters}>
                  Reset filter
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleOk}>
                  Apply filter
                </Button>,
              ]}
              width={720}
            >
              <Row>
                <Col span={24}>
                  <div className="boxusertitles">Attendance Status</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.attendanceStatuses.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false
                              ? "boxLittle"
                              : "boxLittleSelected"
                          }
                          onClick={(data, id) =>
                            this.filterValuesChnages(
                              "attendanceStatuses",
                              index
                            )
                          }
                        >
                          {item.name}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                {/* <Col span={24} style={{marginTop : 10}}>
                            <div className="boxusertitles">Hyperlocal Permission</div>
                            <div className="boxfilterTitles">
                                {this.state.initalFilters.hyperlocalPermission.map((item, index) => (
                                    <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("hyperlocalPermission", index)}>{item.name}</div>
                                ))}
                            </div>
                          </Col> */}
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Hyperlocal Permission</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.hyperlocalPermission.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false
                              ? "boxLittle"
                              : "boxLittleSelected"
                          }
                          onClick={(data, id) =>
                            this.filterValuesChnages(
                              "hyperlocalPermission",
                              index
                            )
                          }
                        >
                          {item.name}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Hyperlocal Eligibility</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.hyperlocalEligibility.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false
                              ? "boxLittle"
                              : "boxLittleSelected"
                          }
                          onClick={(data, id) =>
                            this.filterValuesChnages(
                              "hyperlocalEligibility",
                              index
                            )
                          }
                        >
                          {item.name}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Hyperlocal User Presence</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.hyperlocalAvailability.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false
                              ? "boxLittle"
                              : "boxLittleSelected"
                          }
                          onClick={(data, id) =>
                            this.filterValuesChnages(
                              "hyperlocalAvailability",
                              index
                            )
                          }
                        >
                          {item.name}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Tagged Zone</div>
                  <div className="boxfilterTitles">
                    {this.state?.initalZoneFilter?.taggedZone?.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.status === false
                              ? "boxLittle"
                              : "boxLittleSelected"
                          }
                          onClick={(data, id) =>
                            this.filterValueBox("taggedZone", index)
                          }
                        >
                          {item.name}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  {this.state.locationIds.length > 0 && (
                    <div
                      key="addNewZone"
                      className="boxLittle"
                      onClick={this.getZones}
                    >
                      {" "}
                      <Icon type="plus" /> Zone
                    </div>
                  )}
                </Col>
                {this.props?.customerTagged && (
                  <Col span={24} style={{ marginTop: 10 }}>
                    <div className="boxusertitles">Tagged Clients</div>
                    <div className="boxfilterTitles">
                      {this.state?.initalClientFilter?.taggedClient?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className={
                              item.status === false
                                ? "boxLittle"
                                : "boxLittleSelected"
                            }
                            onClick={(data, id) =>
                              this.filterValueBoxClient("taggedClient", index)
                            }
                          >
                            {item.code}
                          </div>
                        )
                      )}
                    </div>
                  </Col>
                )}
                {this.props?.customerTagged && (
                  <Col span={24} style={{ marginTop: 10 }}>
                    {this.state.locationIds.length > 0 && (
                      <div
                        key="addNewZone"
                        className="boxLittle"
                        onClick={this.getClients}
                      >
                        {" "}
                        <Icon type="plus" /> Client
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            </Modal>
            <Modal
              title="User Actions"
              visible={this.state.visibleUsers}
              onOk={this.usershandleOk}
              onCancel={this.usershandleCancel}
              className="fleetModalFooter"
              maskClosable={false}
              footer={null}
              width={720}
            >
              <Row>
                <Col span={24} style={{ marginTop: 20 }}>
                  <Col span={6}></Col>
                  <Col span={12}>
                    <Input.Search
                      placeholder="Search"
                      allowClear
                      ref={this.InputSearchdtaRef}
                      onChange={(e) => this.selectedUserSearch(e)}
                      onSearch={(value) =>
                        this.applySearchUsers({ partialContentSearch: value })
                      }
                      enterButton
                    />
                  </Col>
                  <Col span={6}></Col>
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                  <div style={{ float: "left", width: "calc(50% - 25px)" }}>
                    <Card ghost="true" style={this.props.style}>
                      <div className="cardTitle">
                        {/* <Checkbox
                          className="selectAll"
                          checked={this.state.disabledstroke}
                          onChange={this.selectAllDisable}
                        ></Checkbox> */}
                        <div className="boxTitle">
                          Disabled Users : {this.state?.disabledUsers.length}
                        </div>
                      </div>
                      <div className="userBox">
                        {this.state.disabledUsers &&
                          this.state.disabledUsers.map((item, index) => (
                            <Col key={index} span={24}>
                              <Col span={2}></Col>
                              <Col span={4}>
                                <Checkbox
                                  checked={this.state.diabledUserBox.includes(
                                    item.userId
                                  )}
                                  onChange={() =>
                                    this.onChekedFordisabled(item.userId)
                                  }
                                ></Checkbox>
                              </Col>
                              <Col span={16}>
                                <div className="username">{item.name}</div>
                                <div className="contactnumber">
                                  {item.contactNumber}
                                </div>
                              </Col>
                              <Col span={2}></Col>
                            </Col>
                          ))}
                      </div>
                    </Card>
                    {this.state?.diabledUserBox && (
                      <div className="userfooter">
                        User Selected : {this.state?.diabledUserBox.length}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      float: "left",
                      width: "50px",
                      paddingRight: 15,
                      paddingLeft: 15,
                    }}
                  >
                    <Icon
                      className="swaping"
                      type="arrow-right"
                      onClick={this.swapRightUser}
                    />
                    <Icon
                      className="swaping"
                      type="arrow-left"
                      onClick={this.swapLeftUser}
                    />
                  </div>
                  <div style={{ float: "left", width: "calc(50% - 25px)" }}>
                    <Card ghost="true" style={this.props.style}>
                      <div className="cardTitle">
                        {/* <Checkbox
                          className="selectAll"
                          checked={this.state.enabledstroke}
                          onChange={this.selectAllEnable}
                        ></Checkbox> */}
                        <div className="boxTitle">
                          Enabled Users : {this.state?.enabledUsers.length}
                        </div>
                      </div>
                      <div className="userBox">
                        {this.state.enabledUsers &&
                          this.state.enabledUsers.map((item, index) => (
                            <Col key={index} span={24}>
                              <Col span={2}></Col>
                              <Col span={4}>
                                <Checkbox
                                  checked={this.state.enabledUserBox.includes(
                                    item.userId
                                  )}
                                  onChange={() =>
                                    this.onChekedForenabled(item.userId)
                                  }
                                ></Checkbox>
                              </Col>
                              <Col span={16}>
                                <div className="username">{item.name}</div>
                                <div className="contactnumber">
                                  {item.contactNumber}
                                </div>
                              </Col>
                              <Col span={2}></Col>
                            </Col>
                          ))}
                      </div>
                    </Card>
                    {this.state?.enabledUserBox && (
                      <div className="userfooter">
                        User Selected : {this.state?.enabledUserBox.length}{" "}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              title={`Selected Zones (${this.state.selectedCountZone})`}
              visible={this.state.visibleZones}
              onOk={this.saveEligibleZone}
              onCancel={this.zoneEligibleCancel}
              maskClosable={false}
              className=""
              footer={null}
              width={320}
            >
              <Row>
                <Col span={24}>
                  <Col span={24}>
                    <Checkbox
                      className="topCheck"
                      checked={this.state.checkallZone}
                      onChange={this.checkallZones}
                    >
                      All Zones
                    </Checkbox>
                    <Input.Search
                      placeholder="Search"
                      allowClear
                      onChange={this.showList}
                      disabled={this.state?.eligibleZones ? false : true}
                      onSearch={this.applySearchzones}
                      enterButton
                    />
                  </Col>
                </Col>
                <Col span={24}>
                  <div ghost="true" style={this.props.style}>
                    <div className="userBox">
                      {this.state.eligibleZones &&
                        this.state.eligibleZones.map((item, index) => (
                          <Col key={index} span={24}>
                            <Col span={2}></Col>
                            <Col span={4}>
                              <Checkbox
                                checked={item.isTagged}
                                onChange={() => this.setEligibleZone(item)}
                              ></Checkbox>
                            </Col>
                            <Col span={16}>
                              <div className="username">{item.zoneName}</div>
                            </Col>
                            <Col span={2}></Col>
                          </Col>
                        ))}
                    </div>
                    <div style={{ marginTop: 20, textAlign: "center" }}>
                      <Button
                        style={{ width: 200 }}
                        type="primary"
                        onClick={this.saveEligibleZone}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              title={`Selected Clients (${this.state.selectedCountClient})`}
              visible={this.state.visibleClients}
              onOk={this.saveEligibleClient}
              onCancel={this.clientEligibleCancel}
              maskClosable={false}
              className=""
              footer={null}
              width={320}
            >
              <Row>
                <Col span={24}>
                  <Col span={24}>
                    <Checkbox
                      className="topCheck"
                      checked={this.state.checkallClient}
                      onChange={this.checkallClients}
                    >
                      All Clients
                    </Checkbox>
                    <Input.Search
                      placeholder="Search"
                      allowClear
                      onChange={this.showListClients}
                      disabled={this.state?.eligibleclients ? false : true}
                      onSearch={this.applySearchClients}
                      enterButton
                    />
                  </Col>
                </Col>
                <Col span={24}>
                  <div ghost="true" style={this.props.style}>
                    <div className="userBox">
                      {this.state.eligibleclients &&
                        this.state.eligibleclients.map((item, index) => (
                          <Col key={index} span={24}>
                            <Col span={2}></Col>
                            <Col span={4}>
                              <Checkbox
                                checked={item.isTagged}
                                onChange={() => this.setEligibleClients(item)}
                              ></Checkbox>
                            </Col>
                            <Col span={16}>
                              <div className="username">
                                {item?.customerCode}
                              </div>
                            </Col>
                            <Col span={2}></Col>
                          </Col>
                        ))}
                    </div>
                    <div style={{ marginTop: 20, textAlign: "center" }}>
                      <Button
                        style={{ width: 200 }}
                        type="primary"
                        onClick={this.saveEligibleClient}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              title={`Selected Zones (${this.state.selectedBoxCountZone})`}
              visible={this.state.visibleboxZones}
              onOk={() => this.saveboxZone(false)}
              onCancel={this.zoneboxCancel}
              maskClosable={false}
              className=""
              footer={null}
              width={320}
            >
              <Row>
                <Col span={24}>
                  <Col span={24} style={{ marginTop: 20 }}>
                    <Checkbox
                      className="topCheck"
                      checked={this.state.checkallBoxZone}
                      onChange={this.checkallZonesBox}
                    >
                      All Zones
                    </Checkbox>
                    <Input.Search
                      placeholder="Search"
                      allowClear
                      onChange={this.showList2}
                      disabled={this.state?.zoneData ? false : true}
                      onSearch={this.applySearchzonesBox}
                      enterButton
                    />
                  </Col>
                </Col>
                <Col span={24}>
                  <div ghost="true" style={this.props.style}>
                    <div className="userBox">
                      {this.state.zoneData &&
                        this.state.zoneData.map((item, index) => (
                          <Col key={index} span={24}>
                            <Col span={2}></Col>
                            <Col span={4}>
                              <Checkbox
                                checked={item.status}
                                onChange={() => this.setEligibleBoxZone(item)}
                              ></Checkbox>
                            </Col>
                            <Col span={16}>
                              <div className="username">{item.name}</div>
                            </Col>
                            <Col span={2}></Col>
                          </Col>
                        ))}
                    </div>
                    <div style={{ marginTop: 20, textAlign: "center" }}>
                      <Button
                        style={{ width: 200 }}
                        type="primary"
                        onClick={() => this.saveboxZone(false)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              title={`Selected Clients (${this.state.selectedBoxCountClient})`}
              visible={this.state.visibleboxClients}
              onOk={() => this.saveboxClient(false)}
              onCancel={this.clientboxCancel}
              maskClosable={false}
              className=""
              footer={null}
              width={320}
            >
              <Row>
                <Col span={24}>
                  <Col span={24} style={{ marginTop: 20 }}>
                    <Checkbox
                      className="topCheck"
                      checked={this.state.checkallBoxClient}
                      onChange={this.checkallClientssBox}
                    >
                      All Zones
                    </Checkbox>
                    <Input.Search
                      placeholder="Search"
                      allowClear
                      onChange={this.showListClient}
                      disabled={this.state?.clientData ? false : true}
                      onSearch={this.applySearchClientsBox}
                      enterButton
                    />
                  </Col>
                </Col>
                <Col span={24}>
                  <div ghost="true" style={this.props.style}>
                    <div className="userBox">
                      {this.state.clientData &&
                        this.state.clientData.map((item, index) => (
                          <Col key={index} span={24}>
                            <Col span={2}></Col>
                            <Col span={4}>
                              <Checkbox
                                checked={item.status}
                                onChange={() => this.setEligibleBoxClient(item)}
                              ></Checkbox>
                            </Col>
                            <Col span={16}>
                              <div className="username">{item.code}</div>
                            </Col>
                            <Col span={2}></Col>
                          </Col>
                        ))}
                    </div>
                    <div style={{ marginTop: 20, textAlign: "center" }}>
                      <Button
                        style={{ width: 200 }}
                        type="primary"
                        onClick={() => this.saveboxClient(false)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              title="COD Eligible/Ineligible"
              visible={this.state.codModal}
              onOk={this.handleCodModalSave}
              onCancel={(e) => {
                this.setState({
                  codModal: false,
                  codModalSelectedKey: {
                    codEligibleFes: [],
                    codInEligibleFes: [],
                  },
                });
              }}
              maskClosable={false}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleCodModalSave}
                  disabled={!this.state.isCodListModiified}
                  loading={this.state.isCodDataSaving}
                >
                  Save
                </Button>,
              ]}
              filterOption={(inputValue, option) => {
                let sep = inputValue.includes(",") ? "," : " ";
                console.log("sepe", sep);
                inputValue = inputValue
                  .split(sep)
                  .find((i) => option.title.includes(i));
                return !!inputValue;
              }}
              width={720}
            >
              <div span={24} className="transfer-list-item">
                <Spin spinning={this.state.codModalLoading}>
                  {bulkCodELInelModalDisabled && (
                    <div style={{ marginBottom: "5px", color: "red" }}>
                      [NOTE: Item Modifierable limit reached of{" "}
                      {ITEM_SELECTION_LIMIT} Items per request. Please save this
                      reqest and try again for rest of the changes]
                    </div>
                  )}
                  <Transfer
                    dataSource={[
                      ...this.state?.codInEligibleFes,
                      ...this.state?.codEligibleFes,
                    ]}
                    targetKeys={this.state?.codEligibleFesTargets}
                    showSearch
                    disabled={bulkCodELInelModalDisabled}
                    showSelectAll={false}
                    onChange={this.handleCodModalChange}
                    selectedKeys={this.state?.codEligibleSelectedKeys}
                    onSelectChange={this.handleBulkCodModalSelectChange}
                    rowKey={(record) => record.userId}
                    titles={["COD Ineligible Users", "COD Eligible Users"]}
                    listStyle={{
                      width: "45%",
                      height: 400,
                    }}
                    filterOption={(inputValue, option) => {
                      inputValue = inputValue
                        .split(" ")
                        .find((i) => option.title.includes(i));
                      return !!inputValue;
                    }}
                    render={(item) => (
                      <>
                        {" "}
                        <span className="list-name">
                          {" "}
                          {item.name}{" "}
                        </span> <br />{" "}
                        <span className="list-phone">
                          {" "}
                          {item.contactNumber}{" "}
                        </span>{" "}
                      </>
                    )}
                  />
                </Spin>
              </div>
            </Modal>
            <Modal
              visible={this.state.showDeliveryHistory}
              onCancel={this.closeDeliveryHistory}
              maskClosable={false}
              className=""
              footer={null}
              width={700}
            >
              <div style={{ maxHeight: 450, overflow: "scroll" }}>
                <iframe
                  src={`https://redash.loadshare.net/embed/query/7541/visualization/12217?api_key=JdhoPBdEJ1IYBLMb4rQg2xLtgu9wWGKYLex08pzm&p_contact_number=${this.state.selectedNumber}`}
                  width="800"
                  height="391"
                ></iframe>
              </div>
            </Modal>
            <Modal
              title="Select a Reason for Toggle"
              visible={
                this.state.isToggleModalVisible ||
                this.state.isToggleIneligibleModalVisible
              }
              width={500}
              onCancel={this.handleToggleModalCancel}
              footer={[
                <Button
                  key="back"
                  type="default"
                  onClick={this.handleToggleModalCancel}
                >
                  Back
                </Button>,
                <Button
                  key="submit"
                  disabled={!this.state.isToggleModalConfirmButton}
                  type="primary"
                  onClick={this.handleToggleSubmit}
                >
                  Submit
                </Button>,
              ]}
            >
              <div className="order-active">
                {this.state.isToggleModalVisible && (
                  <Radio.Group
                    onChange={this.toggleChangeCategories}
                    value={this.state.toggleSelectedReason}
                  >
                    <Radio style={radioStyle} value={"Bad Weather"}>
                      Bad Weather
                    </Radio>
                    <Radio style={radioStyle} value={"Road Blocked"}>
                      Road Blocked
                    </Radio>
                    <Radio style={radioStyle} value={"Bike Issue"}>
                      Bike Issue
                    </Radio>
                    <Radio style={radioStyle} value={"Device Issue"}>
                      Device Issue
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={"Delivery Agent met with an accident"}
                    >
                      Delivery Agent met with an accident
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={
                        "Delivery Agent stopped by local authority / Police"
                      }
                    >
                      Delivery Agent stopped by local authority / Police
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={"Delivery Agent denied servicing the order"}
                    >
                      Delivery Agent denied servicing the order
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={"Delivery Agent not responding to calls"}
                    >
                      Delivery Agent not responding to calls
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={"Do not see a system requirement to add reasons"}
                    >
                      Do not see a system requirement to add reasons
                    </Radio>
                    <Radio style={radioStyle} value={"Under Training"}>
                      Under Training
                    </Radio>
                  </Radio.Group>
                )}
                {this.state.isToggleIneligibleModalVisible && (
                  <Radio.Group
                    onChange={this.toggleChangeCategories}
                    value={this.state.toggleSelectedReason}
                  >
                    <Radio
                      style={radioStyle}
                      value={"Incorrectly Marked Eligible"}
                    >
                      Incorrectly Marked Eligible
                    </Radio>
                    <Radio style={radioStyle} value={"Manually Verified"}>
                      Manually Verified
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={"Do not see a system requirement to add reasons"}
                    >
                      Do not see a system requirement to add reasons
                    </Radio>
                  </Radio.Group>
                )}
                <div style={{ marginTop: 10 }}>
                  {this.state.toggleReasonError && (
                    <Alert
                      message={this.state.toggleReasonError}
                      type="error"
                    />
                  )}
                </div>
              </div>
            </Modal>
          </Row>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fleetStatus: state.FleetReducer,
  cityList: state.FleetReducer?.cityList?.citiesDetails,
  cityListById: state.FleetReducer?.locations,
  users: state.FleetReducer?.hyperlocalUsers,
  customerTagged: state.FleetReducer?.isCustomerTaggingAllowed,
  // customerTagged: true,
  userResponseList: state.FleetReducer?.userResponseList,
  allZones: state.FleetReducer?.allZones,
  allHyperlocalCustomers: state.FleetReducer?.allHyperlocalCustomers,
  zoneData: state.FleetReducer?.zones,
  clientData: state.FleetReducer?.customersBO,
});

const mapDispatchToProps = (dispatch) => ({
  getCityList: () => dispatch(actions.getCityList()),
  getCityListById: (cityId) => dispatch(actions.getCityListById(cityId)),
  fetchUserData: (data) => dispatch(actions.fetchUserData(data)),
  setPermissions: (data) => dispatch(actions.setPermissions(data)),
  getFilterData: (data) => dispatch(actions.getFilterData(data)),
  getEligibleZones: (data) => dispatch(actions.getEligibleZones(data)),
  saveZones: (userId, data) => dispatch(actions.saveZones(userId, data)),
  saveClients: (userId, data) => dispatch(actions.saveClients(userId, data)),
  getZoneData: (payload) => dispatch(actions.getZoneData(payload)),
  getEligibleClients: (data) => dispatch(actions.getEligibleClients(data)),
  getCustomers: () => dispatch(actions.getCustomers()),
  getMultiTaggedEligibility: (payload) =>
    dispatch(actions.getMultiTaggedEligibility(payload)),
  saveMultiTaggedEligibility: (payload) =>
    dispatch(actions.saveMultiTaggedEligibility(payload)),
  getMultiTaggedZones: (payload) =>
    dispatch(actions.getMultiTaggedZones(payload)),
  saveMultiTaggedZones: (payload) =>
    dispatch(actions.saveMultiTaggedZones(payload)),
  getMultiTaggedCustomers: (payload) =>
    dispatch(actions.getMultiTaggedCustomers(payload)),
  saveMultiTaggedCustomers: (payload) =>
    dispatch(actions.saveMultiTaggedCustomers(payload)),
  getCodEligibility: (payload) => dispatch(actions.getCodEligibility(payload)),
  saveCodEligibility: (payload) =>
    dispatch(actions.saveCodEligibility(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FleetDashboard);
